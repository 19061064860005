import {createApp} from 'vue';
import App from './App.vue';
import './assets/styles/style.scss';
import 'bootstrap/dist/js/bootstrap';
import {router} from "@/router";
import i18n from "./i18n/index";
import store from "./store/index";
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'
import CookieConsent from "@/plugins/CookieConsent";

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faArrowUpRightFromSquare,
    faBars,
    faClock,
    faEnvelope,
    faEye,
    faHourglassStart,
    faHouseUser,
    faSearch,
    faUserCircle
} from '@fortawesome/free-solid-svg-icons'
import cookieconsent from "@/config/cookieconsent";

/* add icons to the library */
library.add(faUserCircle)
library.add(faEye)
library.add(faBars)
library.add(faSearch)
library.add(faHouseUser)
library.add(faArrowUpRightFromSquare)
library.add(faClock)
library.add(faEnvelope)
library.add(faHourglassStart)

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(CookieConsent, cookieconsent)
    .use(router);

if (process.env.NODE_ENV !== "development") {
    // app.use(VueMatomo, MATOMO);
}

app.component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');

// Sentry.init({
//     app,
//     // dsn: 'https://6eb9df61455d3f1e87b42635b557edd6@o1359877.ingest.us.sentry.io/4507328941522944',
//     dsn: '',
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["localhost", /^https:\/\/api.the-affordable-turkey\.com/],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

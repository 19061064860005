<script setup>
import {ref} from 'vue';

let consentGiven = ref(false);

const props = defineProps({
  iFrame: String,
  hint: String,
  source: undefined
})
</script>

<template>

  <div class="container">
    <div
        class="mt-5 d-flex flex-column background border-0 rounded-1 p-3 justify-content-between shadow"
        v-if="!consentGiven"
    >
      <section class="pt-3">
        <h5 class="small fw-bolder">{{$t("application.embed.title")}}</h5>
      </section>
      <section class="pt-3" v-if="source !== undefined">
        <p v-if="source==='YouTube'">{{$t("application.embed.youtube")}}</p>
        <p v-if="source==='GoogleMaps'">{{$t("application.embed.googleMaps")}}</p>
      </section>
      <section class="pt-3 small">
        {{ $t("application.embed.reason")}}
      </section>
      <section class="pt-3">
        <label class="du__stripe__switch">
          <input id="du__stripe__activate" v-model="consentGiven" type="checkbox">
          <span class="du__stripe__slider me-2"></span>
          <div class="fw-bolder">{{$t("application.embed.buttonLabel")}}</div>
        </label>
      </section>
      <section class="pt-3 pb-3 small text-gray">
        {{$t("application.embed.consent")}}
      </section>
    </div>
    <div class="mt-5 d-flex flex-column border-0 rounded-1"
         v-if="consentGiven" v-html="iFrame">
    </div>
  </div>
</template>

<style scoped>
.background {
  background: #F7F9FC
}

/* Switch CSS using Flexbox */
.du__stripe__switch {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 100%
}

.du__stripe__switch input {
  display: none;
}

.du__stripe__slider {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 3px;
  transition: background-color 0.4s;
}

.du__stripe__slider:before {
  content: "";
  width: 19px;
  height: 19px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

input:checked + .du__stripe__slider {
  background-color: #2070BB;
}

input:checked + .du__stripe__slider:before {
  transform: translateX(25px);
}
</style>



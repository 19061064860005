<template>
  <div class="row d-flex flex-grow-1">
    <Navigation></Navigation>
    <div class="container mw-lg-60percent d-flex flex-column justify-content-start mt-1">
      <h1 class="entry-title align-self-center mt-5">{{ $t('archive.title') }}</h1>
      <div class="entry-content d-flex justify-content-center flex-column">
        <div class="mt-2 mb-5 text-center">
          {{ $t('archive.curious') }}
        </div>
        <RegisterForm :show-logo=true></RegisterForm>
      </div>
      <div class="entry-content d-flex justify-content-center flex-column align-self-center mb-5">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <router-link class="text-decoration-none nav-link link-primary" to="/avsa-island-balikesir-sea-of-marmara">Issue #1: Avşa
              Island, Balıkesir, Sea of Marmara
            </router-link>
          </li>
          <li class="d-none list-group-item">Dapibus ac facilisis in</li>
          <li class="d-none list-group-item">Morbi leo risus</li>
          <li class="d-none list-group-item">Porta ac consectetur ac</li>
          <li class="d-none list-group-item">Vestibulum at eros</li>
        </ul>
      </div>
      <Footer class="d-flex justify-content-center mb-4"></Footer>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Footer from "@/components/Footer.vue";
import BackToApp from "@/components/BackToApp.vue";
import RegisterForm from "@/components/RegisterForm.vue";

export default {
  name: "Archive",
  components: {RegisterForm, BackToApp, Footer, FontAwesomeIcon, Navigation},
  setup() {
    function toApp() {
      window.location = '/'
    }

    return {
      toApp: toApp
    }
  }
}
</script>

<style scoped>

</style>

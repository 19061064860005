import {createI18n} from 'vue-i18n'
import {AppStorage} from "@/Service/AppStorage";

function loadLocaleMessages() {
    const locales = require.context(
        "./",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

let locale = localStorage.getItem("locale");
if (locale === null) {
    locale = 'en';
}
export default createI18n({
    locale: getLocale(new AppStorage()),
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
})

function getLocale(appStorage) {
    const browserLanguage = navigator.language.split('-')[0];
    const configuredLanguage = appStorage.getPrimaryLanguage();
    if (
        configuredLanguage === null
        || configuredLanguage === undefined
        || configuredLanguage === "undefined"
    ) {
        return browserLanguage;
    }
    return configuredLanguage;
}

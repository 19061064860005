<template>
  <div class="row d-flex flex-grow-1">
    <Navigation></Navigation>
    <div class="container mw-lg-60percent d-flex flex-column justify-content-center mt-5 mb-5 text-black text-center">
      <div class="row">
        <div class="col">
          <h1>{{ $t('howItWorks.title') }}</h1>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          {{ $t('howItWorks.description.first') }}
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <p>{{ $t("howItWorks.description.second") }}</p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">{{ $t("howItWorks.description.featureList.first") }}</li>
            <li class="list-group-item">{{ $t("howItWorks.description.featureList.second") }}</li>
            <li class="list-group-item">{{ $t("howItWorks.description.featureList.third") }}</li>
            <li class="list-group-item">{{ $t("howItWorks.description.featureList.forth") }}</li>
          </ul>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <h3>{{ $t("howItWorks.description.register.title") }}</h3>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <p>{{ $t("howItWorks.description.register.description") }}</p>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <RegisterForm :show-logo=true></RegisterForm>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          {{ $t("howItWorks.description.register.privacy") }}
        </div>
      </div>

      <Footer class="d-flex justify-content-center mb-4"></Footer>

    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import BackToApp from "@/components/BackToApp.vue";

export default {
  name: "HowItWorks",
  components: {BackToApp, RegisterForm, Footer, Navigation},
  setup() {
    function toApp() {
      window.location = '/'
    }

    return {
      toApp: toApp
    }
  }
}
</script>

<style scoped>
</style>

import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";

const globalStore = {
    strict: false,
    plugins: [createPersistedState()],
    state: {
        user: {},
        token: null
    },
    getters: {
        getUser: state => {
            return state.user;
        },
        getToken: state => {
            return state.token;
        }
    },
    mutations: {
        setUser: (state, user) => {
            state.user = user;
        },
        setToken: (state, token) => {
            state.token = token;
        },
        clear: (state) => {
            state.user = {};
            state.token = null;
        },
    }
};

export default createStore(globalStore);

export class LocalStorage {

    get(key, defaultValue = null) {
        const value = localStorage.getItem(key);
        if (null === value) return defaultValue;
        return value;
    }

    set(key, value) {
        localStorage.setItem(key, value);
    }

    remove(key) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }

}
<template>
  <div class="row d-flex flex-grow-1">
    <button class="btn btn-primary" @click.prevent="toApp">{{ $t('backToApp.buttonText') }}</button>
  </div>
</template>

<script>

export default {
  name: "BackToApp",
  setup() {
    function toApp() {
      window.location = '/'
    }

    return {
      toApp: toApp
    }
  }
}
</script>

<style scoped>

</style>

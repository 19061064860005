import {APP_NAME} from "@/config/config";
import store from "@/store";
import axios from "axios";

export const HEADER_NAME_TOKEN = "x-" + APP_NAME + "-token";

export class ApiService {

    constructor(appStorage) {
        this.appStorage = appStorage;
    }

    get(url, data = {}, abortController = null) {
        const config = {
            params: data
            , headers: this.getCustomHeaders()
        };

        if (null !== abortController) {
            config.signal = abortController.signal
        }

        return axios.get(
            url
            , config
        ).catch(
            (response) => {
                const header = response.response.headers['x-' + APP_NAME + '-authentication'] || null;
                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    post(url, data = {}, headers = {}) {

        return axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                ...headers,
                ...this.getCustomHeaders()
            }
        }).catch(
            (response) => {
                const header = response.response.headers['x-' + APP_NAME + '-authentication'] || null;
                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    put(url, data = {}) {

        return axios({
            method: 'put',
            url: url,
            data: data,
            headers: this.getCustomHeaders()
        }).catch(
            (response) => {
                const header = response.response.headers['x-' + APP_NAME + '-authentication'] || null;
                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    delete(url, data = {}) {

        return axios({
            method: 'delete',
            url: url,
            data: data,
            headers: this.getCustomHeaders()
        }).catch(
            (response) => {
                const header = response.response.headers['x-' + APP_NAME + '-authentication'] || null;
                const statusCode = response.response.status;
                if (
                    statusCode === 401
                    && header !== null
                ) {
                    this.appStorage.clear();
                    store.commit('clear');
                    window.location = '/login'
                    return;
                }
                return Promise.reject(response);
            }
        );
    }

    getCustomHeaders() {
        return {
            [HEADER_NAME_TOKEN]: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ1Y2Fyc29sdXRpb25zIiwiYXVkIjp7InR5cGUiOiJ1Y2Fyc29sdXRpb25zIiwidmFsdWUiOiJhYWY4Yzc2NS1lOWI3LTQzOGYtYWU0MC1hMmU5N2Y4YTNmOWQifSwiaWF0IjoxNzE2ODIyNjk2LCJuYmYiOjE3MTY4MjI2OTV9.yh7u5G4oC3E7RTUwLkve0srYUo45o3gX5NOVLym2S4g'
        };
    }

}

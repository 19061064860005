<template>
  <div class="row d-flex flex-grow-1">
    <div class="container mw-lg-60percent d-flex flex-column justify-content-center mt-5 mb-5">
      <section>
        <div class="container text-center">
          <h1 class="display-4 bold">{{ $t("registerConfirm.title") }}</h1>

          <img src="./../assets/svg/logo.svg" :alt="$t('registerConfirm.logoAlt')" height="300px">
        </div>
      </section>

      <section class="newsletter py-5" v-if="state.state.value === 4">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
          </div>
        </div>
      </section>


      <section class="newsletter py-5" v-if="state.state.value === 3">
        <div class="alert alert-warning">
          {{$t("registerConfirm.invalidData.text")}}
        </div>
      </section>

      <section class="newsletter py-5" v-if="state.state.value === 5">
        <div class="alert alert-success">
          {{ $t("registerConfirm.success.text")}}
        </div>
      </section>

      <section class="newsletter py-5" v-if="state.state.value === 6">
        <div class="alert alert-danger">
          {{ $t("registerConfirm.error.text")}}
        </div>
      </section>

      <section class="info py-3 text-center">
        <Footer></Footer>
      </section>

    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import {useRoute} from "vue-router";
import {reactive, toRefs} from "vue";
import {ApiService} from "@/Service/Api/ApiService";
import {AppStorage} from "@/Service/AppStorage";
import {ROUTES} from "@/routes";

export default {
  name: "RegisterConfirm",
  components: {RegisterForm, Footer, Navigation},
  setup() {
    // 1 initing
    // 2 loaded
    // 3 invalid data
    // 4 loading
    // 5 success
    // 6 error
    const state = reactive(
        {
          state: 1
        }
    );
    const route = useRoute()
    const token = route.query.q || null;

    const apiService = new ApiService(
        new AppStorage()
    );

    if (token === null) {
      state.state = 3;
    } else {
      state.state = 4;
      apiService.post(
          ROUTES.SUBSCRIBER_CONFIRM(),
          {
            token: token
          }
      )
          .then(
              () => {
                state.state = 5;
              }
          )
          .catch(
              () => {
                state.state = 6;
              }
          )
    }
    return {
      state: toRefs(state)
    }
  }
}
</script>

<style scoped></style>

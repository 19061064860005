<template>
  <div class="row d-flex flex-grow-1 mt-5 mt-sm-0">
    <div class="container d-flex flex-column justify-content-center mt-5 w-lg-35 w-100">
      <section>
        <div class="container text-center">
          <h1 class="display-4 bold">{{ $t("frontPage.title") }}</h1>
        </div>
      </section>
      <section class="w-lg-100 d-flex justify-content-center flex-column">
        <img class="d-flex w-50 w-lg-100 align-self-center" src="./../assets/svg/logo.svg"
             :alt="$t('frontPage.logoAlt')">
      </section>

      <section>
        <div class="container text-center">
          <p class="lead">{{ $t("frontPage.description") }}</p>
        </div>
      </section>

      <section class="newsletter py-5">
        <RegisterForm></RegisterForm>
      </section>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer.vue";
import RegisterForm from "@/components/RegisterForm.vue";

export default {
  name: "FrontPage",
  components: {RegisterForm, Footer, Navigation},
  setup() {
  }
}
</script>

<style scoped>
.w-35 {
  width: 35% !important;
}
</style>

<template>
  <div class="row d-flex flex-grow-1">
    <Navigation></Navigation>
    <div class="container mw-lg-80percent d-flex flex-column justify-content-start mt-1">

      <div class="inside-article">
        <header class="entry-header"><h1 class="entry-title" itemprop="headline">Datenschutzerklärung</h1></header>
        <div class="entry-content" itemprop="text"><h2 class="wp-block-heading">1. Allgemeines</h2>
          <p>Die Nutzung dieser Website kann mit der Verarbeitung von personenbezogenen Daten verbunden sein. Damit
            diese Verarbeitungen für Sie nachvollziehbar sind, möchten wir Ihnen mit den folgenden Informationen einen
            Überblick zu diesen Verarbeitungen verschaffen. Um eine faire Verarbeitung zu gewährleisten, möchten wir Sie
            außerdem über Ihre Rechte nach der Europäischen Datenschutz-Grundverordnung (DSGVO) und dem
            Bundesdatenschutzgesetz (BDSG) informieren.</p>
          <p>Diese Datenschutzerklärung gilt für alle Verarbeitungen personenbezogener Daten, die wir durchführen,
            sowohl im Zusammenhang mit der Erbringung unserer Dienstleistungen als auch insbesondere auf unseren
            Websites, in mobilen Anwendungen und innerhalb externer Online-Präsenzen, wie zum Beispiel unseren
            Social-Media-Profilen (nachfolgend zusammengefasst als “Onlineangebot” bezeichnet).</p>
          <h3 class="wp-block-heading">Aktualisierung dieser Datenschutzerklärung</h3>
          <p>Wir behalten uns das Recht vor, unsere Datenschutzerklärung zu ändern, falls dies aufgrund neuer
            Technologien notwendig sein sollte. Bitte stellen Sie sicher, dass Ihnen die aktuelle Version vorliegt.
            Werden an dieser Datenschutzerklärung grundlegende Änderungen vorgenommen, geben wir diese auf unserer
            Website bekannt.</p>
          <p><strong>Letzte Änderung: 29. Juni 2024</strong></p>
          <h2 class="wp-block-heading">2. Verantwortlicher</h2>
          <p>Wenn Sie Fragen oder Anregungen zu diesen Informationen haben oder sich wegen der Geltendmachung Ihrer
            Rechte an uns wenden möchten, richten Sie Ihre Anfrage bitte den Verantwortlichen gemäß Art. 13 Abs. 1
            Buchst. a) DSGVO:</p>
          <ul>
            <li><strong>Name:&nbsp;</strong>Ucar Solutions UG (Haftungsbeschränkt)</li>
            <li><strong>Anschrift:</strong>&nbsp;Feldstraße 1, 65719 Hofheim am Taunus</li>
            <li><strong>Handelsregister:</strong>&nbsp;HRB 124001</li>
            <li><strong>Registergericht:</strong>&nbsp;Amtsgericht Frankfurt am Main</li>
            <li><strong>Web:</strong>&nbsp;https://www.ucar-solutions.de</li>
            <li><strong>Vertretungsberechtigte Personen:</strong>&nbsp;Doğan Uçar</li>
            <li><strong>E-Mail-Adresse:</strong>&nbsp;info [at] ucar-solutions.de</li>
            <li><strong>Telefon:</strong>&nbsp;06192 9899814</li>
            <li><strong>Impressum:</strong>&nbsp;https://ucar-solutions.de/impressum/</li>
          </ul>
          <h2 class="wp-block-heading">3. Angaben zur Datenverarbeitung</h2>
          <p>Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung
            zusammen und verweist auf die betroffenen Personen.</p>
          <h3 class="wp-block-heading">Wie erfassen wir Ihre Daten?</h3>
          <p>Ihre Daten werden auf zwei Arten erhoben: Zum einen durch Ihre direkte Angabe, zum Beispiel durch Eingabe
            in ein Kontaktformular. Zum anderen erfassen unsere IT-Systeme automatisch Daten beim Besuch der Website,
            wie technische Informationen (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Diese
            automatische Erfassung beginnt, sobald Sie unsere Website betreten.</p>
          <h3 class="wp-block-heading">Cookies</h3>
          <p>Unsere Webseite verwendet Cookies. Cookies sind Textdateien, die über Ihren Internetbrowser auf Ihrem
            Endgerät gespeichert werden.</p>
          <p>Durch Cookies können wir Ihnen nutzerfreundlichere Services anbieten, die ohne Cookies nicht möglich wären.
            Sie ermöglichen uns, Ihren Browser bei wiederholten Besuchen zu erkennen und Ihnen z.B. das erneute Eingeben
            Ihrer Zugangsdaten zu ersparen. Ein weiteres Beispiel ist das Cookie eines Warenkorbs, das sich die Artikel
            merkt, die Sie in den virtuellen Warenkorb gelegt haben.</p>
          <p>Sie können das Setzen von Cookies jederzeit durch eine entsprechende Einstellung Ihres Browsers verhindern
            und bereits gesetzte Cookies löschen. Beachten Sie, dass durch die Deaktivierung von Cookies möglicherweise
            nicht alle Funktionen unserer Webseite vollumfänglich genutzt werden können. Weitere Informationen zur
            Verwaltung und Löschung von Cookies finden Sie in den Einstellungen Ihres Browsers.</p>
          <h3 class="wp-block-heading">SSL-/TLS-Verschlüsselung</h3>
          <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
            von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw.
            TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten
            mitgelesen werden.</p>
          <h3 class="wp-block-heading">Wofür nutzen wir Ihre Daten?</h3>
          <p>Ein Teil der Daten wird erhoben, um die fehlerfreie Bereitstellung der Website oder der Dienste, die wir
            auf dieser Webseite anbieten, sicherzustellen.</p>
          <p>Diese Informationen verwenden wir, um: Die Inhalte unserer Webseite korrekt auszuliefern, Unsere Webseite
            und Werbung zu optimieren, Die dauerhafte Funktionsfähigkeit unserer IT-Systeme sicherzustellen,
            Strafverfolgungsbehörden im Falle eines Cyberangriffs die notwendigen Informationen bereitzustellen, Die
            erfassten Daten sind anonym und werden, getrennt von personenbezogenen Daten gespeichert. Dies hilft uns,
            den Datenschutz und die Datensicherheit zu erhöhen und ein optimales Schutzniveau für die von uns
            verarbeiteten personenbezogenen Daten zu gewährleisten.</p>
          <p>Andere Daten können zur Analyse Ihres Nutzungsverhaltens verwendet werden.</p>
          <h3 class="wp-block-heading">Allgemeine Angaben zur Datenverarbeitung</h3>
          <p>Wir verarbeiten personenbezogene Daten in Übereinstimmung mit den geltenden Datenschutzgesetzen,
            insbesondere der DSGVO und dem BDSG. Unsere Datenverarbeitung erfolgt ausschließlich auf Basis einer
            gesetzlichen Grundlage. Beim Besuch dieser Website verarbeiten wir personenbezogene Daten nur mit Ihrer
            ausdrücklichen Zustimmung (Art. 6 Abs. 1 lit. a DSGVO), zur Erfüllung eines Vertrages, dessen Vertragspartei
            Sie sind, oder zur Durchführung vorvertraglicher Maßnahmen auf Ihre Anfrage hin (Art. 6 Abs. 1 lit. b
            DSGVO), zur Erfüllung gesetzlicher Pflichten (Art. 6 Abs. 1 lit. c DSGVO) oder zur Wahrung unserer
            berechtigten Interessen oder der berechtigten Interessen Dritter, sofern Ihre Interessen oder Grundrechte
            und -freiheiten, die den Schutz personenbezogener Daten erfordern, nicht überwiegen (Art. 6 Abs. 1 lit. f
            DSGVO).</p>
          <p>Bei jedem Aufruf durch eine betroffene Person oder ein automatisiertes System wird eine Reihe allgemeiner
            Daten und Informationen erfasst. Diese allgemeinen Daten und Informationen werden in den Logfiles des
            Servers gespeichert. Erfasst werden können die (1) verwendeten Browsertypen und -versionen, (2) das vom
            zugreifenden System verwendete Betriebssystem, (3) die Webseite, von welcher ein zugreifendes System auf
            unsere Webseite gelangt (sogenannte Referrer), (4) die Unterseiten, die über ein zugreifendes System auf
            unserer Webseite angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die Webseite, (6) eine
            Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden Systems und (8)
            sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere
            informationstechnologischen Systeme dienen.</p>
          <p>Bei der Nutzung dieser allgemeinen Daten und Informationen werden keine Rückschlüsse auf die betroffene
            Person gezogen. Diese Informationen werden vielmehr benötigt, um (1) die Inhalte unserer Webseite korrekt
            auszuliefern, (2) die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der
            Technik unserer Webseite zu gewährleisten sowie (3) um Strafverfolgungsbehörden im Falle eines Cyberangriffs
            die zur Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym erhobenen Daten und
            Informationen werden daher einerseits statistisch ausgewertet und ferner mit dem Ziel, den Datenschutz und
            die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die von
            uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden
            getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.</p>
          <h3 class="wp-block-heading">Dauer der Speicherung</h3>
          <p>Sofern in den nachfolgenden Hinweisen nichts anderes angegeben ist, speichern wir die Daten nur so lange,
            wie es zur Erreichung des Verarbeitungszwecks oder zur Erfüllung unserer vertraglichen oder gesetzlichen
            Verpflichtungen notwendig ist. Gesetzliche Aufbewahrungspflichten können sich insbesondere aus den handels-
            oder steuerrechtlichen Vorschriften ergeben.</p>
          <p>Wir verarbeiten und speichern personenbezogene Daten nur so lange, wie es zur Erreichung des jeweiligen
            Zwecks erforderlich ist oder wie es durch europäische oder nationale Gesetze vorgeschrieben ist.</p>
          <p>Sobald der Speicherungszweck entfällt oder die gesetzlich vorgeschriebene Speicherfrist abläuft, werden die
            personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder
            gelöscht.</p>
          <h3 class="wp-block-heading">Ihre Rechte</h3>
          <p>Sie haben jederzeit das Recht, kostenlos Auskunft über die Herkunft, Empfänger und den Zweck Ihrer
            gespeicherten personenbezogenen Daten zu erhalten. Zudem haben Sie das Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Für diese Anliegen und weitere Fragen zum Datenschutz können Sie
            sich jederzeit an die im Impressum angegebene Adresse wenden. Darüber hinaus haben Sie das Recht, eine
            Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.</p>
          <p>Bei datenschutzrechtlichen Verstößen steht dem Betroffenen ein Beschwerderecht bei der zuständigen
            Aufsichtsbehörde zu. Die zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
            Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der
            Datenschutzbeauftragten sowie deren Kontaktdaten finden Sie unter folgendem Link:&nbsp;<a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank" rel="noreferrer noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
          </p>
          <p>Sie haben das Recht, Daten, die wir aufgrund Ihrer Einwilligung oder zur Erfüllung eines Vertrags
            automatisiert verarbeiten, in einem gängigen, maschinenlesbaren Format an sich oder an einen Dritten
            aushändigen zu lassen. Wenn Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, sofern es technisch machbar ist.</p>
          <h3 class="wp-block-heading">Widerspruch gegen Werbe-E-Mails</h3>
          <p>Die Verwendung der im Impressum veröffentlichten Kontaktdaten für die Zusendung von unerwünschten Werbe-
            und Informationsmaterialien wird ausdrücklich untersagt. Die Betreiber der Website behalten sich das Recht
            vor, rechtliche Schritte gegen die unaufgeforderte Zusendung von Werbeinformationen, wie etwa durch
            Spam-E-Mails, einzuleiten.</p>
          <h3 class="wp-block-heading">Rechtsgrundlage der Verarbeitung</h3>
          <p>Unsere Datenverarbeitungen basieren auf verschiedenen Rechtsgrundlagen gemäß der DSGVO. Art. 6 Abs. 1 lit.
            a DSGVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
            Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten
            zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies
            beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren oder die Erbringung
            einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 Abs. 1
            lit. b DSGVO. Gleiches gilt für solche Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
            Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen. Unterliegt
            unser Unternehmen einer rechtlichen Verpflichtung, durch welche eine Verarbeitung von personenbezogenen
            Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die
            Verarbeitung auf Art. 6 Abs. 1 lit. c DSGVO. In seltenen Fällen könnte die Verarbeitung von
            personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer
            anderen natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem
            Betrieb verletzt würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige
            lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden
            müssten. Dann würde die Verarbeitung auf Art. 6 Abs. 1 lit. d DSGVO beruhen. Letztlich könnten
            Verarbeitungsvorgänge auf Art. 6 Abs. 1 lit. f DSGVO beruhen. Auf dieser Rechtsgrundlage basieren
            Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die
            Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich
            ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
            Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
            besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen
            sein könnte, wenn die betroffene Person ein Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2
            DSGVO).</p>
          <h3 class="wp-block-heading">Berechtigte Interessen an der Datenverarbeitung</h3>
          <p>Wenn die Verarbeitung personenbezogener Daten auf Artikel 6 Abs. 1 lit. f DSGVO basiert, besteht unser
            berechtigtes Interesse in der Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens aller
            unserer Mitarbeiter und Anteilseigner.</p>
          <h3 class="wp-block-heading">Vorschriften zur Bereitstellung personenbezogener Daten und ihre Notwendigkeit
            für den Vertragsabschluss</h3>
          <p>Wir möchten Sie darauf hinweisen, dass die Bereitstellung personenbezogener Daten teilweise gesetzlich
            vorgeschrieben ist (z.B. Steuervorschriften) oder sich aus vertraglichen Regelungen (z.B. Angaben zum
            Vertragspartner) ergeben kann. Manchmal ist es für den Abschluss eines Vertrags erforderlich, dass Sie uns
            personenbezogene Daten zur Verfügung stellen, die wir anschließend verarbeiten müssen. Wenn Sie mit uns
            einen Vertrag abschließen möchten, sind Sie verpflichtet, die erforderlichen personenbezogenen Daten
            bereitzustellen. Ohne diese Daten können wir den Vertrag nicht abschließen.</p>
          <p>Bevor Sie uns Ihre personenbezogenen Daten bereitstellen, können Sie sich an einen unserer Mitarbeiter
            wenden. Wir klären Sie im Einzelfall darüber auf, ob die Bereitstellung der personenbezogenen Daten
            gesetzlich oder vertraglich vorgeschrieben ist, ob sie für den Vertragsabschluss erforderlich ist, ob eine
            Verpflichtung zur Bereitstellung besteht und welche Folgen eine Nichtbereitstellung der personenbezogenen
            Daten hätte.</p>
          <h3 class="wp-block-heading">Automatisierte Entscheidungsfindung</h3>
          <p>Als Unternehmen setzen wir keine automatisierten Entscheidungsprozesse oder Profiling ein.</p>
          <h3 class="wp-block-heading">Sicherheitsmaßnahmen</h3>
          <p>Wir setzen gemäß Art. 32 DSGVO geeignete technische und organisatorische Maßnahmen ein, um ein dem Risiko
            angemessenes Schutzniveau zu gewährleisten. Dabei berücksichtigen wir den Stand der Technik, die
            Implementierungskosten, sowie die Art, den Umfang, die Umstände und die Zwecke der Verarbeitung. Ebenso
            berücksichtigen wir die unterschiedlichen Eintrittswahrscheinlichkeiten und Schwere des Risikos für die
            Rechte und Freiheiten natürlicher Personen. Zu unseren Maßnahmen gehören insbesondere:</p>
          <ul>
            <li>Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
              Zugangs und<br>des Zugriffs auf die Daten
            </li>
            <li>Kontrolle der Eingabe, Weitergabe und Sicherung der Verfügbarkeit sowie deren Trennung</li>
            <li>Verfahren zur Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf
              Datengefährdungen
            </li>
          </ul>
          <p>Zusätzlich berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung und Auswahl
            von Hardware, Software und Verfahren entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und
            durch datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).</p>
          <h3 class="wp-block-heading">Zusammenarbeit mit Auftragsverarbeitern und Dritten</h3>
          <p>Wenn wir im Rahmen unserer Verarbeitung Daten an andere Personen und Unternehmen (Auftragsverarbeiter oder
            Dritte) offenlegen, übermitteln oder ihnen Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage
            einer gesetzlichen Erlaubnis. Dies kann der Fall sein, wenn die Übermittlung der Daten an Dritte, wie z.B.
            an Zahlungsdienstleister, gemäß Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist, Sie
            eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder es auf Grundlage unserer berechtigten
            Interessen erfolgt (z.B. beim Einsatz von Beauftragten, Webhostern, etc.). Beauftragen wir Dritte mit der
            Verarbeitung von Daten auf Grundlage eines sogenannten „Auftragsverarbeitungsvertrages“, geschieht dies
            gemäß Art. 28 DSGVO.</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h3 class="wp-block-heading">Übermittlungen in Drittländer</h3>
          <p>Wenn wir Daten in einem Drittland (außerhalb der EU oder des EWR) verarbeiten oder dies im Rahmen der
            Nutzung von Diensten Dritter oder bei Offenlegung bzw. Übermittlung von Daten an Dritte geschieht, erfolgt
            dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
            aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht.
            Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse verarbeiten oder lassen wir die Daten in einem
            Drittland nur unter den besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. Das bedeutet, die
            Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung
            eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder unter
            Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (sogenannte
            „Standardvertragsklauseln“).</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h2 class="wp-block-heading">4. Automatisierte Datenverarbeitung</h2>
          <h3 class="wp-block-heading">Verarbeitung von Server-Log-Files</h3>
          <p>Bei der rein informatorischen Nutzung unserer Website werden automatisch allgemeine Informationen erfasst,
            die Ihr Browser an unseren Server sendet, ohne dass eine Registrierung notwendig ist. Dazu gehören in der
            Regel: Browsertyp und -version, verwendetes Betriebssystem, aufgerufene Seite, die zuvor besuchte Seite
            (Referrer URL), IP-Adresse, Datum und Uhrzeit der Serveranfrage sowie der HTTP-Statuscode. Diese
            Verarbeitung erfolgt zur Wahrung unserer berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO und dient
            der technischen Verwaltung und Sicherheit der Website. Die erfassten Daten werden gelöscht, es sei denn, es
            bestehen konkrete Hinweise auf eine rechtswidrige Nutzung, die eine weitere Prüfung und Verarbeitung
            erfordern.</p>
          <h3 class="wp-block-heading">Hosting und E-Mail-Versand</h3>
          <p>Die von uns genutzten Hosting-Leistungen umfassen Infrastruktur- und Plattformdienstleistungen,
            Rechenkapazität, Speicherplatz, Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen und technische
            Wartungsleistungen. Diese sind notwendig, um den Betrieb unseres Onlineangebots sicherzustellen.</p>
          <p>Unser Hostinganbieter verarbeitet dabei Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
            Nutzungsdaten sowie Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern unseres
            Onlineangebots. Dies erfolgt auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h3 class="wp-block-heading">Nutzung von Meta-Komponenten (ehemals Facebook)</h3>
          <p>Unsere Webseite verwendet Komponenten der sozialen Netzwerke Facebook, Instagram und Threads (nachfolgend
            “soziale Medien” genannt), deren Muttergesellschaft Meta Platforms, Inc,Menlo Park, 1 Meta Wy, United
            States. ist. Die europäische Niederlassung ist Meta Platforms Ireland Ltd., 6 Serpentine Ave, Dublin, D04
            H0C9, Irland.</p>
          <p>Facebook ist ein soziales Netzwerk zum Teilen diverser Medien. Instagram ist ein Dienst, der als
            audiovisuelle Plattform zu qualifizieren ist und den Nutzern das Teilen von Fotos und Videos ermöglicht.
            Threads ist ein Dienst zum Teilen von kurzen Textabschnitten (“Kurznachrichten”). Alle soziele Medien
            ermöglichen zudem eine Weiterverbreitung solcher Daten in anderen sozialen Netzwerken.</p>
          <p>Einige unserer Seiten könnten Komponentne aus diesen sozialen Medien beinhalten. Diese Komponenten werden
            nur mit Ihrer Zustimmung geladen. Ohne Ihre Zustimmung werden keine Daten an Meta übertragen. Erst nach
            Ihrer Einwilligung werden Daten übermittelt.</p>
          <p>Bei jedem Besuch unserer Webseite, die eine Meta-Komponente (z.B. Facebook-Plugin) enthält, wird
            automatisch eine Verbindung zwischen Ihrem Browser und den Servern des jeweiligen sozialen Mediums
            hergestellt (falls vorher zugestimmt). Der Meta-Dienst erhält dadurch Informationen über Ihre Besuche auf
            unserer Webseite.</p>
          <p>Falls Sie gleichzeitig bei einem Meta-Dienst eingeloggt sind, kann dieser Dienst Ihren Besuch Ihrem Konto
            des Dienstes zuordnen (falls vorher zugestimmt). Dies gilt auch, wenn Sie keine dieser Komponenten aktiv
            nutzen (z.B. “Gefällt mir”-Button). Das soziele Medium nutzt diese Daten für Werbung, Marktforischung und
            bedarfsgerechte Gestaltung seiner Seiten.</p>
          <p>Ihnen steht ein Widerspruchsrecht zu. Bitte besuchen Sie Datenschutzrichtlinien des jeweiligen sozialen
            Mediums für mehr Informationen. Um die Zuordnung zu Ihrem Konto zu verhindern, loggen Sie sich bitte vor dem
            Besuch unserer Webseite aus Ihrem Facebook-Account aus.</p>
          <p>Weitere Informationen zur Datenerhebung und -nutzung durch Meta finden Sie in den Datenschutzrichtlinien
            des jeweiligen Dienstes.</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h3 class="wp-block-heading">Nutzung von X-Komponenten (ehemals Twitter)</h3>
          <p>Unsere Webseite verwendet Komponenten von X, ehemals Twitter, einem öffentlich zugänglichen
            Mikroblogging-Dienst der X Corp., 1355 Market Street, Suite 900 San Francisco, CA 94103, USA. X ermöglicht
            Nutzern das Veröffentlichen von Kurznachrichten (Tweets) mit bis zu 280 Zeichen, die für alle zugänglich
            sind. Die europäische Niederlassung ist Twitter International Unlimited Company, One Cumberland Place,
            Fenian Street, Dublin 2, D02 AX07, IRELAND.</p>
          <p>X ist ein soziales Netzwerk zum Teilen von textbasierten kurzen Nachrichten (“Kurznachrichten”). X
            ermöglicht eine Weiterverbreitung solcher Daten in anderen sozialen Netzwerken.</p>
          <p>Einige unserer Seiten könnten Komponentne aus X beinhalten. Diese Komponenten werden nur mit Ihrer
            Zustimmung geladen. Ohne Ihre Zustimmung werden keine Daten an X übertragen. Erst nach Ihrer Einwilligung
            werden Daten übermittelt.</p>
          <p>Bei jedem Besuch unserer Webseite, die eine X-Komponente (z.B. eingebettete X Posts) enthält, wird
            automatisch eine Verbindung zwischen Ihrem Browser und den Servern von X hergestellt (falls vorher
            zugestimmt). Der X-Dienst erhält dadurch Informationen über Ihre Besuche auf unserer Webseite.</p>
          <p>Wenn Sie bei X eingeloggt sind, kann X Ihren Besuch unserer Webseite Ihrem X-Account zuordnen (falls vorher
            zugestimmt). Dies geschieht unabhängig davon, ob Sie die X-Komponente anklicken oder nicht. Wenn Sie dies
            nicht möchten, loggen Sie sich bitte vor dem Besuch unserer Webseite aus Ihrem X-Account aus.</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h3 class="wp-block-heading">Nutzung von Alphabet-Komponenten (Google)</h3>
          <p>Unsere Webseite verwendet Komponenten von Alphabet, Google, einem Dienst bekannt hauptsächlich für u.a.
            Suchmaschinen, der Videoplattform “YouTube”, der Analyse-Plattform “Google Analytics”, der Werbeplattform
            “Google Ads” (ehemals “Google Adwords”), Google Adsense, Google Conversion Tracking,
            Google Maps und weitere, der Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043. Die europäische
            Niederlassung ist Gordon House, Barrow Street, Dublin 4, Ireland. Nachfolgend wird “Google” genannt.</p>
          <p>Einige unserer Seiten könnten Alphabet-Komponenten beinhalten. Diese Komponenten werden nur mit Ihrer
            Zustimmung geladen. Ohne Ihre Zustimmung werden keine Daten an den jeweiligen Alphabet übertragen. Erst nach
            Ihrer Einwilligung werden Daten übermittelt.</p>
          <p>Bei jedem Besuch unserer Webseite, die eine Alphabet-Komponente enthält, wird automatisch eine Verbindung
            zwischen Ihrem Browser und den Servern von des Alphabet-Dienstes hergestellt (falls vorher zugestimmt). Der
            Dienst erhält dadurch Informationen über Ihre Besuche auf unserer Webseite.</p>
          <p>Wenn Sie bei einem der Dienste von Alphabet eingeloggt sind, kann dieser Dienst Ihren Besuch unserer
            Webseite Ihrem Alphabet Account zuordnen (falls vorher zugestimmt). Dies geschieht unabhängig davon, ob Sie
            die Komponente anklicken oder nicht. Wenn Sie dies nicht möchten, loggen Sie sich bitte vor dem Besuch
            unserer Webseite aus Ihrem Alphabet-Account aus.</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <p>Wir haben mit Google einen Auftragsverarbeitungsvertrag abgeschlossen, um sicherzustellen, dass Google die
            Daten nur gemäß unseren Weisungen und unter Einhaltung der geltenden Datenschutzvorschriften
            verarbeitet.</p><h4 class="wp-block-heading">Nutzung von YouTube</h4>
          <p>Unsere Webseite verwendet Komponenten von YouTube, einem Internet-Videoportal von Alphabet (Google)
            (genauer Name und Anschrift siehe Einleitung). YouTube ermöglicht Video-Publishern, kostenlos Videos
            hochzuladen und anderen Nutzern, diese Videos kostenlos anzusehen, zu bewerten und zu kommentieren.</p>
          <p>Beim Aufruf einer Seite mit eingebettetem YouTube-Video wird eine Verbindung zu den YouTube-Servern
            hergestellt, um das Video darzustellen. Dabei wird an YouTube übermittelt, welche unserer Seiten Sie besucht
            haben. Das Plugin wird ohne Branding geladen.</p>
          <p>YouTube-Videos werden nur mit Ihrer Zustimmung geladen, sodass keine Daten an YouTube übertragen werden,
            wenn Sie keine Zustimmung erteilt haben. Erst nach Ihrer Zustimmung können die Videos abgespielt und Daten
            an YouTube übermittelt werden.</p>
          <p>Wenn Sie bei YouTube eingeloggt sind, kann YouTube Ihren Besuch unserer Webseite Ihrem Benutzerkonto
            zuordnen. Diese Zuordnung erfolgt auch beim Anklicken des Start-Buttons eines Videos. Sie können dies
            verhindern, indem Sie sich vor der Nutzung unserer Webseite aus Ihrem YouTube- und Google-Konto abmelden und
            die entsprechenden Cookies löschen.</p>
          <p>Weitere Informationen zur Datenerhebung und -verarbeitung durch YouTube finden Sie in der
            Datenschutzerklärung von Google. Google verarbeitet Ihre personenbezogenen Daten auch in den USA.</p><h4
              class="wp-block-heading">Nutzung von Google Analytics</h4>
          <p>Unsere Webseite verwendet Google Analytics, einen Webanalysedienst von Alphabet (Google) (genauer Name und
            Anschrift siehe Einleitung). Google Analytics verwendet Cookies, die auf Ihrem Endgerät gespeichert werden
            und eine Analyse Ihrer Nutzung unserer Webseite ermöglichen. Die durch Cookies erzeugten Informationen
            werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.</p>
          <p>Auf unserer Webseite ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse innerhalb der EU
            oder des EWR von Google gekürzt, bevor sie übertragen wird. Nur in Ausnahmefällen wird die volle IP-Adresse
            in die USA übertragen und dort gekürzt. Google verwendet diese Informationen, um Ihre Nutzung der Webseite
            auszuwerten, Berichte über die Webseitenaktivitäten zu erstellen und weitere Dienstleistungen für uns zu
            erbringen. Die IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
          <p>Die Nutzung von Google Analytics hilft uns, das Nutzerverhalten auf unserer Webseite zu analysieren und
            unser Angebot zu verbessern. Diese Verarbeitung erfolgt auf Basis Ihrer Einwilligung gemäß Art. 6 Abs. 1
            lit. a DSGVO.</p>
          <p>Sie können Ihre Einwilligung jederzeit widerrufen. Zusätzlich können Sie das Speichern von Cookies durch
            eine entsprechende Einstellung Ihres Browsers verhindern. Beachten Sie, dass in diesem Fall möglicherweise
            nicht alle Funktionen der Webseite genutzt werden können. Um die Erhebung und Verarbeitung der durch Cookies
            erzeugten Daten (einschließlich Ihrer IP-Adresse) durch Google zu verhindern, können Sie ein Browser-Plugin
            herunterladen und installieren:&nbsp;<a href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                                    target="_blank" rel="noreferrer noopener">Google Analytics Opt-out
              Browser Add-on.</a></p><h4 class="wp-block-heading">Nutzung von Google Ads</h4>
          <p>Unsere Webseite verwendet Google Ads, einen Online-Werbedienst von Alphabet (Google) (genauer Name und
            Anschrift siehe Einleitung). Google Ads verwendet Cookies, die auf Ihrem Endgerät gespeichert werden, und
            Web Beacons, um Cookies zu setzen. Diese Daten werden in die USA übertragen und dort ausgewertet. Wenn Sie
            in Ihrem Google-Account eingeloggt sind, können Ihre Daten diesem Konto zugeordnet werden. Um dies zu
            vermeiden, loggen Sie sich bitte aus. Google kann diese Daten an Vertragspartner, Dritte und Behörden
            weitergeben.</p>
          <p>Wir nutzen Google Ads zu Marketing- und Optimierungszwecken, insbesondere um relevante Anzeigen zu
            schalten, die Kampagnenleistung zu verbessern und doppelte Anzeigen zu vermeiden. Diese Verarbeitung erfolgt
            auf Basis Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Die Speicherdauer der Daten beträgt 6
            Monate.</p>
          <p>Wir nutzen Google “AdWords” darüber hinaus, um Anzeigen im Google-Werbenetzwerk zu platzieren (z.B. in
            Suchergebnissen, in Videos, auf Webseiten), die Nutzern angezeigt werden, die mutmaßliches Interesse an den
            Anzeigen haben. Dies ermöglicht uns, Anzeigen gezielter innerhalb unseres Onlineangebots zu präsentieren, um
            Nutzern nur Anzeigen zu zeigen, die potentiell deren Interessen entsprechen. Wenn einem Nutzer
            beispielsweise Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Onlineangeboten
            interessiert hat, spricht man von “Remarketing”.</p>
          <p>Zu diesem Zweck wird beim Aufruf unserer und anderer Webseiten, auf denen das Google-Werbenetzwerk aktiv
            ist, durch Google ein Code ausgeführt und es werden (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch
            “Web Beacons” genannt) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein
            individuelles Cookie gespeichert (statt Cookies können auch vergleichbare Technologien verwendet werden). In
            dieser Datei wird vermerkt, welche Webseiten der Nutzer besucht, für welche Inhalte er sich interessiert und
            welche Angebote er geklickt hat. Ferner werden technische Informationen zum Browser und Betriebssystem,
            verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebots gespeichert.</p>
          <p>Wir erhalten außerdem ein individuelles “Conversion-Cookie”. Die mit Hilfe des Cookies eingeholten
            Informationen dienen Google dazu, Conversion-Statistiken für uns zu erstellen. Wir erfahren jedoch nur die
            anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem
            Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit
            denen sich Nutzer persönlich identifizieren lassen. Die Daten der Nutzer werden im Rahmen des
            Google-Werbenetzwerks pseudonym verarbeitet.</p>
          <p>Sie können Ihre Einwilligung jederzeit widerrufen. Zusätzlich können Sie das Speichern von Cookies durch
            eine entsprechende Einstellung Ihres Browsers verhindern. Beachten Sie, dass in diesem Fall möglicherweise
            nicht alle Funktionen der Webseite genutzt werden können.</p>
          <p>Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten sowie zum
            Datenschutz finden Sie auf folgenden Webseiten von Google.</p><h4 class="wp-block-heading">Nutzung von
            Google Adsense</h4>
          <p>Unsere Webseite verwendet Google Ads, einen Dienst von Alphabet (Google) (genauer Name und Anschrift siehe
            Einleitung). Google Ads ermöglicht es uns, durch Werbemittel (Google Ads) auf externen Webseiten auf unsere
            Angebote aufmerksam zu machen und die Effektivität unserer Werbemaßnahmen zu messen.</p>
          <p>Google Ads verwendet sogenannte Ad Server Cookies, die auf Ihrem Endgerät gespeichert werden, wenn Sie über
            eine Google-Anzeige auf unsere Webseite gelangen. Diese Cookies speichern Analyse-Werte wie die Anzahl der
            Einblendungen und Klicks, Ihre pseudonymisierte IP-Adresse um den Erfolg der Anzeigen zu messen. Diese
            Cookies sind in der Regel 30 Tage gültig und dienen nicht der persönlichen Identifikation.</p>
          <p>Google kann Ihren Browser durch die gespeicherten Cookies wiedererkennen. Wenn Sie eine Webseite eines
            Ads-Kunden besuchen und das Cookie noch aktiv ist, können Google und der Ads-Kunde feststellen, dass Sie auf
            die Anzeige geklickt haben. Jeder Ads-Kunde erhält ein eigenes Cookie, sodass die Cookies nicht über
            verschiedene Webseiten nachverfolgt werden können. Wir erhalten von Google lediglich statistische
            Auswertungen, keine personenbezogenen Daten.</p>
          <p>An einigen Stellen dieser Webseite können AdSense mit personalisierten Anzeigen eingesetzt werden. Google
            zieht dabei auf Grundlage der von Nutzern besuchten Websites oder verwendeten Apps und den so erstellten
            Nutzerprofilen Rückschlüsse auf deren Interessen. Werbetreibende nutzen diese Informationen, um ihre
            Kampagnen an diesen Interessen auszurichten, was für Nutzer und Werbetreibende gleichermaßen von Vorteil
            ist.</p>
          <p>An einigen Stellen dieser Webseite können AdSense mit nicht-personalisierten Anzeigen eingesetzt werden.
            Diese Anzeigen basieren nicht auf Nutzerprofilen oder früherem Nutzerverhalten. Stattdessen werden
            Kontextinformationen genutzt, z.B. ein grobes geografisches Targeting basierend auf dem aktuellen Standort,
            dem Inhalt der aktuellen Webseite oder App sowie aktuellen Suchbegriffen.</p>
          <p>Durch die Nutzung von Google Ads wird automatisch eine Verbindung zu Google-Servern hergestellt. Google
            erhält dadurch Informationen über Ihre Besuche auf unserer Webseite und kann diese ggf. Ihrem Google-Konto
            zuordnen, falls Sie eingeloggt sind. Auch ohne Google-Konto kann Ihre IP-Adresse erfasst und gespeichert
            werden.</p>
          <p>Wir verwenden Google Ads zu Marketing- und Optimierungszwecken, um relevante und interessante Anzeigen zu
            schalten und die Leistung unserer Werbekampagnen zu verbessern. Diese Verarbeitung erfolgt auf Basis Ihrer
            Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Die Speicherdauer der Daten beträgt 6 Monate.</p>
          <p>Sie können Ihre Einwilligung jederzeit widerrufen. Zusätzlich können Sie das Speichern von Cookies durch
            eine entsprechende Einstellung Ihres Browsers verhindern. Beachten Sie, dass in diesem Fall möglicherweise
            nicht alle Funktionen der Webseite genutzt werden können.</p>
          <h4 class="wp-block-heading">Einsatz von Google Conversion Tracking</h4>
          <p>Wir nutzen im Rahmen des Google AdWords-Dienstes auch das sogenannte Conversion-Tracking. Wenn Sie auf eine
            von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem
            Computer/Endgerät gespeichert. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit, enthalten keine
            personenbezogenen Daten und dienen daher nicht der persönlichen Identifizierung. Die mit Hilfe des
            Conversion-Cookies gesammelten Informationen werden verwendet, um Conversion-Statistiken für AdWords-Kunden
            zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Rechtsgrundlage für diese
            Datenverarbeitung ist Art. 6 Abs. 1 lit. a DSGVO. Wir verwenden die für diesen Dienst erforderlichen Cookies
            (sogenannte Marketing-Cookies) nur mit Ihrer Zustimmung.</p><h4 class="wp-block-heading">Einsatz von Google
            Maps</h4>
          <p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google (genauer Name und Anschrift siehe
            Einleitung).</p>
          <p>Google Maps speichert einige Informationen über Sie und Ihr Endgerät. Diese Informationen werden in der
            Regel an einen Server von Google in den USA übertragen und dort gespeichert. Wir haben keinen Einfluss auf
            diese Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zur einheitlichen Darstellung der
            Schriftarten Google Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die benötigten Web Fonts
            in den Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
          <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote
            und zur leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt
            wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
            TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
            des Nutzers (z.B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
            widerrufbar.</p>
          <h3 class="wp-block-heading">Nutzung von LinkedIn</h3>
          <p>Unsere Webseite verwendet Komponenten von LinkedIn, einem sozialen Netzwerk für Geschäftsbeziehungen,
            betrieben von LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA. Für
            Datenschutzangelegenheiten außerhalb der USA ist LinkedIn Ireland Unlimited Company, Wilton Plaza Wilton
            Place, Dublin 2 Irland, zuständig.</p>
          <p>Einige unserer Seiten könnten LinkedIn-Komponenten beinhalten. Diese Komponenten werden nur mit Ihrer
            Zustimmung geladen. Ohne Ihre Zustimmung werden keine Daten an LinkedIn übertragen. Erst nach Ihrer
            Einwilligung werden Daten übermittelt.</p>
          <p>Bei jedem Aufruf einer Seite mit einer LinkedIn-Komponente (z.B. LinkedIn-Plug-In) wird automatisch eine
            Verbindung zu den LinkedIn-Servern hergestellt (falls vorher zugestimmt). LinkedIn erfährt dadurch, welche
            Seiten unserer Webseite Sie besucht haben (falls vorher zugestimmt).</p>
          <p>Wenn Sie bei LinkedIn eingeloggt sind, kann LinkedIn Ihren Besuch unserer Webseite Ihrem LinkedIn-Konto
            zuordnen (falls vorher zugestimmt). Dies geschieht auch, wenn Sie die LinkedIn-Komponente nicht aktiv nutzen
            (falls vorher zugestimmt). Um dies zu vermeiden, loggen Sie sich bitte vor dem Besuch unserer Webseite aus
            Ihrem LinkedIn-Account aus.</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h3 class="wp-block-heading">Nutzung von Altcha Captcha</h3>
          <p>Wir verwenden “Altcha, Kostenlose, Open-Source Captcha-Alternative”, einen Dienst von BAU Software s.r.o.,
            Lidicka 700/19 Brno 602 00 Czechia. Altcha speichert und verarbeitet lediglich Informationen zur
            Verarbeitung Ihrer Anfrage wenn Sie unsere (Kontakt-)Formulare auf unserer Webseite nutzen.</p>
          <p>Altcha verwendet nach eigenen Angaben keine Marketing- oder Tracking-Cookies oder setzt Fingerprinting ein.
            Altcha setzt Cloudflare als Dienstanbieter ein, welcher jedoch temporäre Sicherheits-Cookies setzen kann, um
            Nutzer zu authentifizieren und betrügerische Aktivitäten zu verhindern. Diese Cookies sind für das
            Funktionieren von Altcha sowie deren API-Dienste unerlässlich und erfassen keine personenbezogenen
            Daten.</p>
          <p>Unser berechtigtes Interesse an der Verarbeitung der Daten durch Google liegt in der Sicherstellung der
            Sicherheit unserer Webseite. Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. f DSGVO.</p>
          <p>Sie können die Installation von Cookies verhindern, indem Sie vorhandene Cookies löschen und die
            Speicherung von Cookies in den Einstellungen Ihres Webbrowsers deaktivieren. Beachten Sie jedoch, dass Sie
            in diesem Fall möglicherweise nicht alle Funktionen unseres Internetauftritts vollumfänglich nutzen
            können.</p>
          <h2 class="wp-block-heading">5. Datenverarbeitung durch Nutzerangaben</h2>
          <p>Auf unserer Webseite werden Daten durch Nutzerangaben gesammelt. Nachfolgend erhalten Sie einen Überblick
            zu den verschiedenen Möglichkeiten.</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h3 class="wp-block-heading">Newsletter Abonnement</h3>
          <p>Auf unserer Webseite bieten wir Ihnen die Möglichkeit, unseren Newsletter zu abonnieren. Dabei werden die
            personenbezogenen Daten, die Sie in die Eingabemaske eintragen, an uns übermittelt.</p>
          <p>Um unseren Newsletter zu erhalten, benötigen Sie eine gültige E-Mail-Adresse und müssen sich für den
            Newsletterversand registrieren. Mit dem Ausfüllen und Klick auf “Registrieren” sind Sie sofort zu dem
            Newsletter angemeldet und können sich jederzeit wieder abmelden.</p>
          <p>Bei der Anmeldung speichern wir die IP-Adresse des verwendeten Computers sowie Datum und Uhrzeit der
            Anmeldung. Diese Daten helfen uns, einen möglichen Missbrauch Ihrer E-Mail-Adresse nachvollziehen zu können
            und dienen unserer rechtlichen Absicherung.</p>
          <p>Die erhobenen Daten verwenden wir ausschließlich für den Versand unseres Newsletters. In bestimmten Fällen
            informieren wir Sie per E-Mail über Änderungen am Newsletterangebot oder technische Anpassungen. Ihre Daten
            werden nicht an Dritte weitergegeben.</p>
          <p>Sie können den Newsletter jederzeit kündigen und Ihre Einwilligung zur Speicherung Ihrer Daten widerrufen.
            In jedem Newsletter finden Sie dazu einen entsprechenden Abmeldelink.</p><h4 class="wp-block-heading">
            Newsletter Tracking</h4>
          <p>Unsere Newsletter enthalten Zählpixel, die es uns ermöglichen, die Interaktion mit den E-Mails zu
            analysieren.</p>
          <p>Zählpixel sind kleine, unsichtbare Grafiken, die in HTML-E-Mails eingebettet werden. Sie werden verwendet,
            um Informationen darüber zu sammeln, wie E-Mails genutzt werden. Wenn Sie eine E-Mail mit einem Zählpixel
            öffnen, wird eine Verbindung zu unseren Servern hergestellt, wodurch bestimmte Daten erfasst werden.</p>
          <p>Mit Hilfe der Zählpixel können wir nachvollziehen: Ob und wann eine E-Mail geöffnet wurde, Welche Links in
            der E-Mail angeklickt wurden.</p>
          <p>Diese Informationen helfen uns: Den Erfolg unserer E-Mail-Kampagnen zu messen, Unsere Newsletter zu
            verbessern und besser an Ihre Interessen anzupassen.Die erhobenen Daten werden nicht an Dritte weitergegeben
            und dienen ausschließlich der Optimierung unseres Newsletterversands.</p>
          <p>Sie können Ihre Einwilligung zur Nutzung dieser Daten jederzeit widerrufen. Ein Widerruf führt dazu, dass
            wir Ihre personenbezogenen Daten löschen. Eine Abmeldung vom Newsletter wird automatisch als Widerruf
            gewertet.</p>
          <h3 class="wp-block-heading">Elektronische Kontaktformular und andere Formulare über die Webseite</h3>
          <p>Unsere Webseite enthält gesetzlich vorgeschriebene Angaben, die eine schnelle elektronische Kontaktaufnahme
            mit uns ermöglichen, einschließlich einer allgemeinen E-Mail-Adresse.</p>
          <p>Wenn Sie uns per E-Mail oder über ein Formular auf dieser Webseite kontaktieren, werden Ihre übermittelten
            personenbezogenen Daten automatisch gespeichert. Diese Daten verwenden wir ausschließlich zur Bearbeitung
            Ihrer Anfrage oder zur Kontaktaufnahme mit Ihnen. Eine Weitergabe an Dritte erfolgt nicht. Die Verarbeitung
            der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO).</p>
          <p>Auf Wunsch kann bei Kontaktaufnahme auch der Newsletter abonniert werden. Dieser Möglichkeit ist optional
            und hat keinen Einfluss auf die Art und Weise wie Ihre Anfrage bearbeitet oder beantwortet wird. Sie können
            jederzeit das Abonnement deaktieren. Für nähere Information siehe “Newsletter Abonnement”.</p>
          <p>Etwaige Zusammenarbeit, Übermittlung von Daten oder Verwendung von Dienstleistungen oder Speicherung von
            Daten geschieht auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Bereitstellung unseres Onlineangebots gemäß Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit Art. 28 DSGVO
            (Abschluss eines Auftragsverarbeitungsvertrags).</p>
          <h2 class="wp-block-heading">6. Ihre Rechte</h2>
          <p>Sie haben das Recht, Ihre Betroffenenrechte uns gegenüber geltend zu machen. Dazu gehören insbesondere
            folgende Rechte:</p>
          <ul>
            <li>Auskunftsrecht: Nach Art. 15 DSGVO und § 34 BDSG haben Sie das Recht zu erfahren, ob und in welchem
              Umfang wir<br>personenbezogene Daten zu Ihrer Person verarbeiten.
            </li>
            <li>Recht auf Berichtigung: Gemäß Art. 16 DSGVO können Sie die Berichtigung Ihrer Daten verlangen.Sie haben
              das<br>Recht, unverzüglich die Berichtigung unrichtiger personenbezogener Daten zu verlangen. Ferner haben
              Sie das<br>Recht, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.
            </li>
            <li>Recht auf Löschung: Nach Art. 17 DSGVO und § 35 BDSG haben Sie das Recht, die Löschung Ihrer
              personenbezogenen<br>Daten zu verlangen. Sie haben das Recht, von uns zu verlangen, dass Ihre
              personenbezogenen Daten unverzüglich<br>gelöscht werden, sofern einer der folgenden Gründe zutrifft und
              die Verarbeitung nicht erforderlich ist:
              <ul>
                <li>Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise
                  verarbeitet<br>wurden, nicht mehr notwendig.
                </li>
                <li>Sie widerrufen Ihre Einwilligung und es fehlt an einer anderweitigen Rechtsgrundlage für die<br>Verarbeitung.
                </li>
                <li>Sie legen Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
                  für<br>die Verarbeitung vor.
                </li>
                <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                <li>Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung<br>erforderlich.
                </li>
                <li>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft<br>erhoben.
                </li>
              </ul>
              <br>Möchten Sie dieses Löschungsrecht in Anspruch nehmen, können Sie sich jederzeit an uns wenden. Wir
              werden<br>veranlassen, dass Ihrem Löschverlangen unverzüglich nachgekommen wird.
            </li>
            <li>Recht auf Einschränkung der Verarbeitung: Gemäß Art. 18 DSGVO können Sie die Einschränkung der
              Verarbeitung<br>Ihrer personenbezogenen Daten verlangen. Sie haben das Recht, von uns die Einschränkung
              der Verarbeitung zu<br>verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
              <ul>
                <li>Sie bestreiten die Richtigkeit der personenbezogenen Daten für eine Dauer, die es uns ermöglicht,
                  die<br>Richtigkeit der Daten zu überprüfen.
                </li>
                <li>Die Verarbeitung ist unrechtmäßig und Sie lehnen die Löschung der personenbezogenen Daten ab und<br>verlangen
                  stattdessen die Einschränkung der Nutzung der Daten.
                </li>
                <li>Wir benötigen die personenbezogenen Daten nicht länger für die Zwecke der Verarbeitung, Sie
                  benötigen<br>diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                </li>
                <li>Sie haben Widerspruch gegen die Verarbeitung eingelegt und es steht noch nicht fest, ob unsere<br>berechtigten
                  Gründe gegenüber Ihren überwiegen.
                </li>
              </ul>
              <br><br>Möchten Sie dieses Recht auf Einschränkung der Verarbeitung in Anspruch nehmen, können Sie sich
              jederzeit an uns<br>wenden. Wir werden die Einschränkung der Verarbeitung veranlassen.
            </li>
            <li>Recht auf Datenübertragbarkeit: Nach Art. 20 DSGVO haben Sie das Recht, die Sie betreffenden
              personenbezogenen<br>Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
              maschinenlesbaren Format zu<br>erhalten und diese Daten einem anderen Verantwortlichen zu übermitteln,
              sofern:
              <ul>
                <li>Die Verarbeitung auf einer Einwilligung oder einem Vertrag beruht und</li>
                <li>Die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
              </ul>
              <br>Ferner haben Sie das Recht zu erwirken, dass die personenbezogenen Daten direkt von uns an einen
              anderen<br>Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Möchten Sie dieses
              Recht auf<br>Datenübertragbarkeit in Anspruch nehmen, können Sie sich jederzeit an uns wenden.
            </li>
            <li>Widerspruchsrecht: Gemäß Art. 21 Abs. 1 DSGVO können Sie gegen jede Verarbeitung, die auf Art. 6 Abs. 1
              Buchst.<br>e) oder f) DSGVO beruht, Widerspruch einlegen. Bei Direktwerbung haben Sie gemäß Art. 21 Abs. 2
              und Abs. 3 DSGVO<br>das Recht, jederzeit Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen.
            </li>
            <li>Widerrufsrecht: Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese gemäß
              Art. 7<br>Abs. 3 DSGVO jederzeit widerrufen. Der Widerruf berührt nicht die Rechtmäßigkeit der aufgrund
              der Einwilligung<br>bis zum Widerruf erfolgten Verarbeitung.
            </li>
            <li><br>Recht auf Auskunft: Sie haben das Recht, jederzeit unentgeltlich Auskunft über die zu Ihrer Person
              gespeicherten<br>personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Dazu gehören
              Informationen über:
              <ul>
                <li>Die Verarbeitungszwecke</li>
                <li>Die Kategorien personenbezogener Daten, die verarbeitet werden</li>
                <li>Die Empfänger oder Kategorien von Empfängern, gegenüber denen die Daten offengelegt wurden oder
                  werden,<br>insbesondere bei Empfängern in Drittländern oder internationalen Organisationen
                </li>
                <li>Die geplante Dauer der Speicherung oder die Kriterien für die Festlegung dieser Dauer</li>
                <li>Das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
                  Widerspruch<br>gegen die Verarbeitung
                </li>
                <li>Das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
                <li>Die Herkunft der Daten, wenn diese nicht bei Ihnen erhoben wurden</li>
                <li>Das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22
                  Abs. 1<br>und 4 DSGVO und — zumindest in diesen Fällen — aussagekräftige Informationen über die
                  involvierte Logik<br>sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
                  Verarbeitung für Sie
                </li>
              </ul>
              <br>Ferner haben Sie das Recht zu erfahren, ob personenbezogene Daten an ein Drittland oder eine
              internationale<br>Organisation übermittelt wurden und welche geeigneten Garantien im Zusammenhang mit der
              Übermittlung bestehen.<br>Möchten Sie dieses Auskunftsrecht in Anspruch nehmen, können Sie sich jederzeit
              an uns wenden.
            </li>
            <li>Recht auf Widerspruch: Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer personenbezogenen
              Daten, die<br>aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen. Dies
              gilt auch für ein auf<br>diese Bestimmungen gestütztes Profiling. Wir verarbeiten Ihre personenbezogenen
              Daten im Falle des Widerspruchs<br>nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für
              die Verarbeitung nachweisen, die Ihre<br>Interessen, Rechte und Freiheiten überwiegen oder die
              Verarbeitung dient der Geltendmachung, Ausübung oder<br>Verteidigung von Rechtsansprüchen. Verarbeiten wir
              personenbezogene Daten, um Direktwerbung zu betreiben, haben<br>Sie das Recht, jederzeit<br>Widerspruch
              gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Möchten<br>Sie
              dieses Widerspruchsrecht in Anspruch nehmen, können Sie sich jederzeit an uns wenden.
            </li>
          </ul>
          <h3 class="wp-block-heading">Beschwerderecht bei einer Aufsichtsbehörde</h3>
          <p>Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DSGVO verstößt,
            haben Sie gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren.</p>
          <h2 class="wp-block-heading">7. Onlinepräsenzen in sozialen Medien</h2>
          <p>Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven
            Kunden, Interessenten und Nutzern zu kommunizieren und sie über unsere Leistungen zu informieren.</p>
          <p>Bitte beachten Sie, dass dabei Daten der Nutzer außerhalb der Europäischen Union verarbeitet werden können.
            Dies kann für die Nutzer Risiken mit sich bringen, da die Durchsetzung ihrer Rechte erschwert werden könnte.
            US-Anbieter, die unter dem Privacy-Shield zertifiziert sind, haben sich verpflichtet, die
            Datenschutzstandards der EU einzuhalten.</p>
          <p>Die Daten der Nutzer werden in der Regel für Marktforschungs- und Werbezwecke verarbeitet. Aus dem
            Nutzungsverhalten und den daraus resultierenden Interessen der Nutzer können Nutzungsprofile erstellt
            werden. Diese Profile können genutzt werden, um gezielte Werbeanzeigen innerhalb und außerhalb der
            Plattformen zu schalten, die den mutmaßlichen Interessen der Nutzer entsprechen. Hierzu werden normalerweise
            Cookies auf den Geräten der Nutzer gespeichert, die Informationen über das Nutzungsverhalten und die
            Interessen der Nutzer enthalten. Die Nutzungsprofile können auch geräteübergreifend gespeichert werden,
            insbesondere wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und eingeloggt sind.</p>
          <p>Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten
            Interessen an einer effektiven Information und Kommunikation mit den Nutzern gemäß Art. 6 Abs. 1 lit. f
            DSGVO. Falls die Nutzer von den jeweiligen Anbietern um eine Einwilligung in die Datenverarbeitung gebeten
            werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a, Art. 7 DSGVO.</p>
          <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out)
            verweisen wir auf die Angaben des jweiligen Anbieters.</p></div>
      </div>
      <section class="info py-3 text-center">
        <Footer></Footer>
      </section>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Footer from "@/components/Footer.vue";
import BackToApp from "@/components/BackToApp.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import InfoBox from "@/components/InfoBox.vue";
import Embed from "@/components/Embed.vue";
import {Pagination} from "vue3-carousel";
import FactBox from "@/components/FactBox.vue";

export default {
  name: "Archive",
  components: {FactBox, Pagination, Embed, InfoBox, RegisterForm, BackToApp, Footer, FontAwesomeIcon, Navigation},
  setup() {

  }
}
</script>

<style scoped>

</style>

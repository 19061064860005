<template>
  <div class="d-flex justify-content-center mb-2" v-if="state.form.value.state === 3">
    <div class="spinner-border" role="status">
    </div>
  </div>
  <div class="alert alert-success" role="alert" v-if="state.form.value.state === 4">
    {{ $t("registerForm.success.text") }}
  </div>
  <div class="alert alert-danger" role="alert" v-if="state.form.value.state === 5">
    {{ $t("registerForm.invalidEmail.text") }}
  </div>
  <div class="alert alert-warning" role="alert" v-if="state.form.value.state === 6">
    {{ $t("registerForm.alreadySubscribed.text") }}
  </div>
  <form class="d-flex flex-column flex-lg-row align-content-center justify-content-center align-items-center"
        @submit="onFormSubmit">

    <div class="container justify-content-center align-items-center d-flex" v-if="showLogo">

      <img class="bi me-2" style="height: 40vh" src="./../assets/svg/9936445_4318688.svg" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="d-flex flex-row col border rounded justify-content-between flex-grow-1"
             :class="state.form.value.border">

          <input class="border-0 flex-grow-1 pt-05 pb-05 no-outline bg-white" type="search"
                 :placeholder="$t('registerForm.form.emailInput.placeholder')" v-model="state.form.value.address">
          <div class="align-self-center" v-if="state.form.value.address === null || state.form.value.address === ''">
            <font-awesome-icon icon="envelope"/>
          </div>
        </div>
      </div>
      <div class="row mt-1 align-items-stretch d-flex">
        <div class="col flex-grow-1 ps-0 pe-0 align-items-stretch d-flex">
          <button class="btn btn-primary w-100">{{ $t('registerForm.form.button.text') }}</button>
        </div>
      </div>

      <div class="row mt-1 align-items-center d-flex">
        <altcha-widget
            class="align-self-center d-flex justify-content-center"
            challengeurl="https://eu.altcha.org/api/v1/challenge?apiKey=ckey_01834684de453a695e873811589a"
        ></altcha-widget>
        <a class="align-self-center d-flex justify-content-center" href="https://altcha.org">Protected by ALTCHA</a>
      </div>
    </div>


  </form>
</template>

<script>
import Navigation from "@/components/Navigation";
import {reactive, toRefs, watch} from "vue";
import {AppStorage} from "@/Service/AppStorage";
import {ApiService} from "@/Service/Api/ApiService";
import {ROUTES} from "@/routes";
import Footer from "@/components/Footer.vue";
import _ from "lodash";
import 'altcha';

export default {
  name: "RegisterForm",
  components: {Footer, Navigation},
  props: {
    showLogo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  // state
  // 1 = starting
  // 2 = loaded
  // 3 = loading
  // 4 = success
  // 5 = invalid email
  // 6 = user already exists
  setup() {
    const state = reactive(
        {
          form: {
            address: null,
            state: 1,
            border: null
          }
        }
    );
    state.form.state = 2;

    watch(() => state.form.address, (newVal, oldVal) => {
      const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };

      _.debounce(
          () => {
            if (newVal === '' || newVal === null || validateEmail(newVal)) {
              state.form.border = null
              return;
            }
            state.form.border = 'border-red'
          },
          200
      )()
    }, {deep: true});


    const apiService = new ApiService(
        new AppStorage()
    );

    function onFormSubmit(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      state.form.state = 3;

      apiService.post(
          ROUTES.SUBSCRIBER_CREATE(),
          {
            email: state.form.address,
          }
      )
          .then(
              () => {
                state.form.address = null;
                state.form.state = 4;
                // setTimeout(
                //     () => {
                //       state.form.state = 2;
                //     },
                //     5000
                // )
              }
          )
          .catch(
              (r) => {
                console.log(r)
                const statusCode = r.response.status || 500;
                if (statusCode === 400) {
                  state.form.state = 5;
                }
                if (statusCode === 422) {
                  state.form.state = 6;
                }
                setTimeout(
                    () => {
                      state.form.state = 2;
                    },
                    5000
                )
              }
          )
    }

    return {
      state: toRefs(state),
      onFormSubmit: onFormSubmit
    }
  }
}
</script>

<style scoped>

.no-outline {
  outline: none;
}

.pt-05 {
  padding-top: 0.24rem !important;
}

.pb-05 {
  padding-bottom: 0.24rem !important;
}

.border-red {
  border-color: #58151c !important;
}
</style>

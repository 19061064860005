// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
import {createRouter, createWebHistory} from "vue-router";
import HowItWorks from "@/components/HowItWorks";
import Application from "@/components/Application.vue";
import store from "@/store";
import FrontPage from "@/components/FrontPage.vue";
import Archive from "@/components/Archive.vue";
import RegisterConfirm from "@/components/RegisterConfirm.vue";
import AvsaIsland from "@/components/Issues/AvsaIsland.vue";
import NotFound from "@/components/NotFound.vue";
import Privacy from "@/components/Privacy.vue";

const routes = [
    {path: '/', component: FrontPage},
    {path: '/application', component: Application},
    {path: '/how-it-works', component: HowItWorks},
    {path: '/register-confirm', component: RegisterConfirm},
    {path: '/archive', component: Archive},
    {path: '/privacy', component: Privacy},
    {path: '/avsa-island-balikesir-sea-of-marmara', component: AvsaIsland},
    {path: '/:catchAll(.*)*', component: NotFound},
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach(
    async (to) => {
        return true;
        // redirect to login page if not logged in and trying to access a restricted page
        const publicPages = [
            '/how-it-works',
            '/archive',
            '/register-confirm',
            '/privacy',
            '/avsa-island-balikesir-sea-of-marmara',
            '/'
        ];

        const authRequired = !isRouteInPublicPages(to.path, publicPages);
        if (authRequired && store.getters.getToken === null) {
            // store.commit('clear');
            return '/';
        }
    }
)

function isRouteInPublicPages(route, publicPages) {
    const baseRoute = route.includes('?') ? route.split('?')[0] : route;
    return publicPages.includes(baseRoute);
}

<template>
  <div class="row d-flex flex-grow-1">
    <Navigation></Navigation>
    <div class="container mw-lg-80percent d-flex flex-column justify-content-start mt-1">

      <!--            <p class="entry-title align-self-start mt-3">Main Page > Issues > Avsa Island</p>-->
      <img src="./../../assets/webp/issues/1/avsa-island-turkey.webp" class="img-fluid h-50 mt-3"
           alt="Houses on Avsa Island">

      <div class="d-flex flex-wrap ps-lg-3 ps-1 pe-1">
        <div class="left-side border shadow rounded-1 border-0 ps-lg-4 pe-lg-4">
          <h1 class="entry-title align-self-center">Issue #1: Avşa Island, Balıkesir, Sea of Marmara</h1>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row align-items-center">
              <img src="./../../assets/dogan-ucar-profile-picture.jpg"
                   class="img-fluid rounded-circle" style="max-width: 50px"
                   alt="Profile Picture of Article Author">
              <div class="ms-2">
                <div>Dogan Ucar</div>
                <div class="text-gray">25. May 2024</div>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center">
              <font-awesome-icon icon="clock"/>
              <div class="ms-2 text-gray">
                9 Minutes
              </div>
            </div>
            <div class="text-gray">Last Update: 25. May 2024</div>
          </div>
          <div class="mt-5">
            <Embed
                i-frame='<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12133.365867996681!2d27.48831127341748!3d40.511943042788296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b419cb9603b347%3A0x844abb052c97946e!2zQXbFn2E!5e0!3m2!1sde!2sde!4v1716894953067!5m2!1sde!2sde" loading="lazy" class="border-0 w-100" style="min-height: 50vh" allowFullScreen="" referrerPolicy="no-referrer-when-downgrade" ></iframe>'
                source="GoogleMaps"
            >
            </Embed>

          </div>
          <div class="mt-5">
            <div class="container-fluid my-5">
              <h2 class="text-center ">Discovering the Hidden Gems of Avşa Island</h2>
              <p>Nestled in the sparkling waters of the Sea of Marmara, Avşa Island is a gem waiting to be
                discovered. The island, renowned for its vibrant history and serene beauty, offers a perfect blend of
                culture, nature, and relaxation. As you step off the ferry, the charm of Avşa immediately captivates
                you, and every corner of the island beckons with a unique story.</p>
              <p>There is <strong>Yiğitler Köyü</strong>, a quaint village known for its warm hospitality
                and picturesque landscapes. Walking through its narrow streets, you are greeted by traditional houses
                and friendly locals who are always ready to share tales of the island's past. Yiğitler is also home to
                some of the finest wine factories in the region. The vineyards here
                produce exceptional wines, thanks to the island's fertile soil and favorable climate. A visit to these
                wineries offers not only a taste of exquisite wines but also a glimpse into the meticulous process of
                winemaking.</p>
              <p>Next to Yiğitler, <strong>Mavi Koy</strong>, a stunning blue bay that lives up to its name, is one of
                the spots that needs to get visited. The crystal-clear waters are perfect for swimming
                and snorkeling, and the tranquil environment
                provides a perfect escape from the hustle and bustle of daily life. Nearby, you'll find
                <strong>Kumtur</strong>, a golden sandy beach where the gentle waves lap at your feet, creating a
                soothing symphony of nature.</p>
              <p><strong>Çınar Koyu</strong>, a serene cove shaded by ancient plane trees, is also a lovely place on
                Avsa Island. This spot is perfect for a peaceful afternoon picnic or a quiet moment of reflection. The
                lush greenery and the cool shade of the trees offer a refreshing retreat on a hot summer day.</p>
              <p>Another village worth visiting is <strong>Türkeli Köyü</strong>. This charming settlement showcases
                traditional Turkish rural life and offers a variety of local delicacies that are sure to tantalize your
                taste buds. Don't miss out on the <strong>Kadınlar Plajı</strong>, a women-only beach that provides a
                safe and private space for women to enjoy the sun and sea.</p>
              <p>As evening falls, the vibrant <strong>Barlar Sokağı</strong> comes alive. This lively street is lined
                with bars and clubs where the music and laughter continue into the early hours. It's the perfect place
                to experience the island's nightlife and make new friends.</p>
              <p>For a touch of history, <strong>Manastır</strong>, an old monastery that stands as a
                testament to the island's rich heritage, is always worth a visit. The serene atmosphere and the stunning
                views from this historic
                site make it a must-see. Nearby, the <strong>Tarihi Çınaraltı</strong>, an ancient plane tree, stands as
                a silent witness to the passage of time and the stories of generations.</p>
              <p>The island is dotted with beautiful bays, each offering its unique charm. <strong>Altınkum
                Koyu</strong> is known for its golden sands and clear waters, perfect for a relaxing day by the sea.
                <strong>Şahintepesi</strong>, or Falcon Hill in English, provides a panoramic view of the island and the
                surrounding sea, making it a favorite spot for photographers and nature lovers alike.</p>
              <p>For those seeking a more secluded experience, <strong>Karadut Koyu</strong> is a hidden paradise with
                its untouched natural beauty and tranquil waters. Meanwhile, <strong>Değirmenardı Mevkii</strong> offers
                a picturesque landscape with old windmills and stunning sea views.</p>
              <p>Avşa Island is not limited to those bays, here we listed just some of them. Another worth to mention
                bay is <strong>Beyazsaray Koyu Plajı</strong>, a pristine beach known for its
                white sands and calm waters. This beach is ideal for families and those looking to unwind in a serene
                environment.</p>
              <p>Avşa Island is a place where every visitor can find their own slice of paradise. From
                its charming villages and historic sites to its stunning beaches and vibrant nightlife, the island
                promises an unforgettable experience for all who venture to its shores.</p>
            </div>
            <div class="d-flex flex-column mb-4 text-center">
              <img src="./../../assets/webp/issues/1/avsa-island-drone-view.webp" class="img-fluid h-50"
                   alt="Drone View of Avşa Island">
              <p class="text-gray small ">Drone View of Avşa Island, Source: <a
                  href="https://www.youtube.com/watch?v=cGmIR7JmQE8&ab_channel=GezsekDeBeraberiz"
                  class="text-decoration-none">GezsekDeBeraberiz@YouTube
                <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"/>
              </a>
              </p>
            </div>

            <h2 class="text-center mb-4">Urbanization on Avşa Island</h2>
            <p>Avşa Island, a tranquil retreat in the Sea of Marmara, has undergone significant urban development over
              recent decades. This transformation reflects broader trends in Turkey's urbanization strategy, which
              emphasizes both economic growth and improved living standards.</p>

            <p>Urbanization in Turkey has been rapid, particularly since the 1980s. The urban population has more than
              doubled, driven by extensive infrastructure projects and economic policies favoring urban expansion.
              Avşa Island, with its strategic location and appeal as a tourist destination, has been part of this
              broader trend.</p>

            <p>The development on Avşa Island has included the construction of new residential areas, improved
              transportation links, and enhanced public amenities. Key infrastructure projects include the establishment
              of modern housing estates and the expansion of ferry services, which have made the island more accessible
              and boosted its appeal as a vacation spot.</p>

            <p>While urbanization has brought economic benefits, it has also posed challenges. The increase in
              construction activities has led to concerns about environmental sustainability and the preservation of the
              island's natural beauty. Very promising efforts are ongoing to balance development with environmental
              protection,
              ensuring that urban growth does not compromise the island’s ecological integrity.</p>

            <p>Tourism has been a significant driver of Avşa's urbanization. The island's pristine beaches, such as
              Altınkum and Karadut, attract numerous visitors, leading to the growth of local businesses and services.
              This influx has necessitated the development of accommodations, dining, and recreational facilities,
              further transforming the island’s landscape.</p>

            <p>Looking ahead, the focus is on sustainable development that harmonizes urban growth with environmental
              stewardship. Plans include enhancing infrastructure while preserving green spaces and cultural heritage
              sites. This balanced approach aims to maintain Avşa Island’s charm and appeal as both a residential and
              tourist destination.</p>

            <p>In summary, the urbanization of Avşa Island reflects a microcosm of Turkey’s broader urbanization agenda,
              marked by rapid development, economic opportunities, and the ongoing challenge of sustainable growth.</p>

            <p>The following video by GezsekDeBeraberiz on YouTube shows Avşa Islands beatiful diversity:</p>

            <div class="d-flex flex-column mb-4 text-center ">
              <Embed
                  i-frame='<iframe src="https://www.youtube.com/embed/cGmIR7JmQE8?si=FWAtnNFxPCeFq2Ry" title="YouTube video player" style="min-height: 50vh" class="border-0 w-100" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>'
                  source="YouTube"
              ></Embed>
              <p class="text-gray small ">Drone View of Avşa Island, Source: <a
                  href="https://www.youtube.com/watch?v=cGmIR7JmQE8&ab_channel=GezsekDeBeraberiz"
                  class="text-decoration-none">GezsekDeBeraberiz@YouTube
                <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"/>
              </a>
              </p>
            </div>

            <p class="mt-3">
              <InfoBox
                  text="This property was last reviewed on May 31, 2024. All information provided here is based on the advertisement available at that time. Details may have changed since then. Please contact us for the most up-to-date information."></InfoBox>
              <h2 class="text-center mb-5 mt-5">Avşa Island's Property of this Post: The Alaçatı House</h2>

              Houses built with Alaçatı stone, known for its distinctive beauty and durability, are a hallmark of the
              Alaçatı region in Turkey. This unique limestone, quarried locally, is prized for its aesthetic appeal,
              characterized by a warm, natural hue and a texture that adds a rustic charm to buildings. Alaçatı stone
              houses not only exude a timeless elegance but also offer practical benefits, such as excellent insulation
              properties that keep interiors cool in the summer and warm in the winter. These houses are often
              complemented by traditional architectural features like wooden shutters and red-tiled roofs, creating a
              picturesque and harmonious blend with the surrounding landscape. The use of Alaçatı stone in construction
              is
              a testament to the region's rich heritage and craftsmanship, making these homes highly sought after for
              their beauty and historical significance.
            </p>

            <Carousel id="gallery" class="mt-5 mb-5" :items-to-show="1" :wrap-around="true" v-model="currentSlide">
              <Slide v-for="(image, index) in images" :key="index">
                <div class="carousel__item">
                  <img :src="image.url" :alt="'Slide ' + (index + 1)"/>
                </div>
              </Slide>

              <template #addons>
                <Pagination/>
                <p class="small text-gray">Image@Coldwell Banker Turkey </p>
              </template>
            </Carousel>

            <p>
              This property is a 5+1 villa for sale on Avşa Island in the Marmara district of Balıkesir. The villa is
              situated on a 300m² plot with a 200m² enclosed usage area, including a 100m² footprint. It is located 600
              meters from Manastır Bay and is clad with Alacati stone. The property includes a 200m² garden and comes
              with an independent title deed.

              The villa is priced at 13,200,000 TL and is located in Avşa Mahallesi, Marmara, Balıkesir. It has a gross
              area of 250m² and a net area of 200m², with 5+1 rooms, 2 bathrooms, and 2 floors. The building is between
              5-10 years old, uses stove heating, and includes open parking. The property is not furnished,
              owner-occupied, and not within a site. The deed status is independent.
            </p>

            <div class="d-flex flex-column align-items-center mt-5 mb-5 ">
              <h2 class="h2">In Short</h2>
              <div class="row">
                <div class="col">
                  <FactBox text="Plot Size: 300m²"></FactBox>
                </div>
                <div class="col">
                  <FactBox text="Property Type: Villa"></FactBox>
                </div>
                <div class="col">
                  <FactBox text="Enclosed Area: 200m² (100m² footprint)"></FactBox>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <FactBox text="Exterior: Clad with Alacati Stone"></FactBox>
                </div>
                <div class="col">
                  <FactBox text="Garden Area: 200m²"></FactBox>
                </div>
                <div class="col">
                  <FactBox text="Price: 13,200,000 TL"></FactBox>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="right-side ps-3 pt-3 pe-2">
          <div class="border rounded-1 text-center p-3 shadow">
            <h5 class="entry-title align-self-center mt-5">No Other Issues Posted Yet!</h5>
            <font-awesome-icon icon="hourglass-start" size="5x" class="mt-5" style="color: #0d6efd"/>
            <div class="text-gray mt-5 mb-5">No other issues so far posted. Come back again later or subscribe to the
              newsletter in order to stay up
              to date
            </div>
            <div class="mb-5">
              <RegisterForm></RegisterForm>
            </div>
          </div>
        </div>
      </div>
      <section class="info py-3 text-center">
        <Footer></Footer>
      </section>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Footer from "@/components/Footer.vue";
import BackToApp from "@/components/BackToApp.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import InfoBox from "@/components/InfoBox.vue";
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Navigation as Vue3Navigation, Pagination, Slide} from 'vue3-carousel';
import theAlacatiOne from '@/assets/webp/issues/1/the-alacati-house-1.jpeg';
import theAlacatiTwo from '@/assets/webp/issues/1/the-alacati-house-2.jpeg';
import theAlacatiThree from '@/assets/webp/issues/1/the-alacati-house-3.jpeg';
import theAlacatiFour from '@/assets/webp/issues/1/the-alacati-house-4.jpeg';
import FactBox from "@/components/FactBox.vue";
import Embed from "@/components/Embed.vue";

export default {
  name: "AvsaIsland",
  components: {
    Embed,
    FactBox,
    InfoBox,
    RegisterForm,
    BackToApp,
    Footer,
    FontAwesomeIcon,
    Navigation,
    Carousel,
    Slide,
    Pagination, Vue3Navigation
  },
  setup() {
    function toApp() {
      window.location = '/'
    }

    function slideTo(val) {
      this.currentSlide = val
    }

    function getImage(imagePath) {
      return require(imagePath);
    }

    return {
      toApp: toApp,
      slideTo: slideTo,
      getImage: getImage,
      images: [
        {
          id: 1,
          url: theAlacatiOne,
          alt: 'Ala'
        },
        {
          id: 2,
          url: theAlacatiTwo
        },
        {
          id: 3,
          url: theAlacatiThree
        },
        {
          id: 4,
          url: theAlacatiFour
        }
      ],
      currentSlide: 0
    }
  }
}
</script>

<style scoped>
.custom-div {
  display: flex;
  flex-wrap: wrap;
}

.left-side {
  background-color: #FFFFFF;
  position: relative;
  padding-top: 50%; /* Adjust this value based on your image height */
}

.right-side {
  background-color: #FFFFFF;
}

@media (min-width: 768px) {
  .left-side {
    width: 70%;
    top: -100px; /* Adjust this value based on your image height */
    padding-top: 100px; /* Adjust this value based on your image height */
  }

  .right-side {
    width: 30%;
  }
}

@media (max-width: 767.98px) {
  .left-side {
    width: 100%;
    top: 0;
    padding-top: 1rem !important;
  }

  .right-side {
    width: 100%;
  }
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
  max-width: 100%;
  height: auto;
}

</style>

<template>
  <div class="alert alert-warning text-center" role="alert">
    {{ this.text }}
  </div>
</template>

<script>

export default {
  name: "InfoBox",
  components: {},
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup() {
    return {}
  }
}
</script>

<style scoped>

</style>

<template>
  <nav class="navbar navbar-expand-lg bottom-nav text-center">
    <div class="container">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <router-link class="text-decoration-none nav-link" to="/">{{ $t('footer.start') }}</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link text-decoration-none" target="_blank"
             href="https://ucar-solutions.de/impressum/">{{ $t('footer.imprint') }}
            <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"/>
          </a>
        </li>
        <li class="nav-item">
          <router-link class="text-decoration-none nav-link" to="/archive">{{ $t('footer.archive') }}</router-link>
        </li>
        <li class="nav-item">
          <router-link class="text-decoration-none nav-link" to="/how-it-works">{{
              $t('footer.howItWorks')
            }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="text-decoration-none nav-link" to="/privacy">{{
              $t('footer.privacy')
            }}
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link text-decoration-none" href="https://ucar-solutions.de/about/"
             target="_blank">{{ $t('footer.vendor') }}
            <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']"/>
          </a>
        </li>

      </ul>
    </div>
  </nav>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
  name: "Footer",
  components: {Navigation},
  setup() {
    return {}
  }
}
</script>

<style scoped>
.vendor-link {
  text-decoration: none;
}

.bottom-nav {
  margin-top: auto;
}

</style>

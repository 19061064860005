const TOGGLE_NAME_NECESSARY = 'necessary';
const TOGGLE_NAME_ANALYTICS = 'analytics';
const TOGGLE_NAME_TARGETING = 'targeting';

export default {
    mode: 'opt-in',
    hideFromBots: true,
    onConsent: onConsentCustom,
    onFirstConsent: onConsentCustom,
    disablePageInteraction: true,
    categories: {
        necessary: {
            enabled: true,
            readOnly: true,
            services: {
                gr: {
                    label: 'Google Recaptcha',
                    cookies: [
                        {
                            name: 'garecaptcha'
                        }
                    ]
                }
            }
        },
        analytics: {
            enabled: false,
            readOnly: false,
            services: {
                ga: {
                    label: 'Google Analytics',
                    cookies: [
                        {
                            name: /^(_ga|_gid)/
                        }
                    ]
                }
            }
        }
    },
    language: {
        default: 'en',
        autoDetect: 'browser',
        translations: {
            en: {
                consentModal: {
                    title: 'This website uses cookies',
                    description: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.',
                    acceptAllBtn: 'Accept All',
                    acceptNecessaryBtn: 'Reject All',
                    showPreferencesBtn: 'Manage Preferences'
                },
                preferencesModal: {
                    title: 'Manage Cookie Preferences',
                    acceptAllBtn: 'Accept All',
                    acceptNecessaryBtn: 'Reject All',
                    savePreferencesBtn: 'Accept Selection',
                    closeIconLabel: 'Close',
                    sections: [
                        {
                            title: 'This website uses cookies',
                            description: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.'
                        },
                        {
                            title: 'Necessary',
                            description: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'More information',
                            description: 'For any queries in relation to the policy on cookies and your choices, please <a href="https://ucar-solutions.de/kontakt">contact us</a>'
                        }
                    ]
                }
            },
            de: {
                consentModal: {
                    title: "Diese Website verwendet Cookies",
                    description: "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, um Funktionen für soziale Medien bereitzustellen und um unseren Datenverkehr zu analysieren. Wir geben auch Informationen über Ihre Nutzung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter, die diese mit anderen Informationen kombinieren können, die Sie ihnen zur Verfügung gestellt haben oder die sie aus Ihrer Nutzung ihrer Dienste gesammelt haben.",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Manage Preferences"
                },
                preferencesModal: {
                    title: "Cookie-Einstellungen verwalten",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Auswahl akzeptieren",
                    closeIconLabel: "Close",
                    sections: [
                        {
                            title: "Diese Website verwendet Cookies",
                            description: "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, um Funktionen für soziale Medien bereitzustellen und um unseren Datenverkehr zu analysieren. Wir geben auch Informationen über Ihre Nutzung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter, die diese mit anderen Informationen kombinieren können, die Sie ihnen zur Verfügung gestellt haben oder die sie aus Ihrer Nutzung ihrer Dienste gesammelt haben."
                        },
                        {
                            title: "Necessary",
                            description: "Notwendige Cookies helfen dabei, eine Website nutzbar zu machen, indem sie grundlegende Funktionen wie die Seitennavigation und den Zugang zu sicheren Bereichen der Website ermöglichen. Ohne diese Cookies kann die Website nicht richtig funktionieren.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Weitere Informationen",
                            description: "Bei Fragen zur Cookie-Politik und Ihren Wahlmöglichkeiten <a href=\"https://ucar-solutions.de/kontakt\">kontaktieren Sie uns</a> bitte"
                        }
                    ]
                }
            },
            es: {
                consentModal: {
                    title: "Este sitio web utiliza cookies",
                    description: "Utilizamos cookies para personalizar el contenido y los anuncios, para proporcionar funciones de medios sociales y para analizar nuestro tráfico. También compartimos información sobre su uso de nuestro sitio con nuestros socios de medios sociales, publicidad y análisis que pueden combinarla con otra información que les haya proporcionado o que hayan recopilado de su uso de sus servicios.",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "RechazarTodo",
                    showPreferencesBtn: "Gestionar preferencias"
                },
                preferencesModal: {
                    title: "Gestionar preferencias de cookies",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "RechazarTodo",
                    savePreferencesBtn: "Aceptar selección",
                    closeIconLabel: "Cerrar",
                    secciones: [
                        {
                            title: "Este sitio web utiliza cookies",
                            description: "Utilizamos cookies para personalizar el contenido y los anuncios, para proporcionar funciones de medios sociales y para analizar nuestro tráfico. También compartimos información sobre su uso de nuestro sitio con nuestros socios de medios sociales, publicidad y análisis que pueden combinarla con otra información que les haya proporcionado o que hayan recopilado de su uso de sus servicios."
                        },
                        {
                            title: "Necesarias",
                            description: "Las cookies necesarias ayudan a que un sitio web sea utilizable al permitir funciones básicas como la navegación por páginas y el acceso a áreas seguras del sitio web. El sitio web no puede funcionar correctamente sin estas cookies.",
                            linkedCategory: "necesarias"
                        },
                        {
                            title: "Más información",
                            description: "Para cualquier consulta en relación con la política de cookies y sus opciones, <a href=\"https://ucar-solutions.de/kontakt\">póngase en contacto con nosotros</a>"
                        }
                    ]
                }
            },
            fr: {
                consentModal: {
                    title: "Ce site web utilise des cookies",
                    description: "Nous utilisons des cookies pour personnaliser le contenu et les publicités, pour fournir des fonctions de médias sociaux et pour analyser notre trafic. Nous partageons également des informations sur votre utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse qui peuvent les combiner avec d'autres informations que vous leur avez fournies ou qu'ils ont collectées à partir de votre utilisation de leurs services.",
                    acceptAllBtn: "Accepter tout",
                    acceptNecessaryBtn: "Reject All",
                    showPreferencesBtn: "Gérer les préférences"
                },
                preferencesModal: {
                    title: "Gérer les préférences en matière de cookies",
                    acceptAllBtn: "Accepter tout",
                    acceptNecessaryBtn: "Reject All",
                    savePreferencesBtn: "Accepter la sélection",
                    closeIconLabel: "Close",
                    sections: [
                        {
                            title: "Ce site web utilise des cookies",
                            description: "Nous utilisons des cookies pour personnaliser le contenu et les publicités, pour fournir des fonctions de médias sociaux et pour analyser notre trafic. Nous partageons également des informations sur votre utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse, qui peuvent les combiner avec d'autres informations que vous leur avez fournies ou qu'ils ont collectées lors de votre utilisation de leurs services."
                        },
                        {
                            title: "Necessary",
                            description: "Les cookies nécessaires contribuent à rendre un site web utilisable en permettant des fonctions de base telles que la navigation sur les pages et l'accès aux zones sécurisées du site web. Le site web ne peut pas fonctionner correctement sans ces cookies",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Plus d'informations",
                            description: "Pour toute question relative à la politique en matière de cookies et à vos choix, veuillez <a href=\"https://ucar-solutions.de/kontakt\">nous contacter</a>"
                        }
                    ]
                }
            },
            it: {
                consentModal: {
                    title: "Questo sito web utilizza i cookie",
                    description: "Utilizziamo i cookie per personalizzare i contenuti e gli annunci, per fornire funzioni di social media e per analizzare il nostro traffico. Condividiamo inoltre le informazioni sull'utilizzo del nostro sito con i nostri partner di social media, pubblicità e analisi, che possono combinarle con altre informazioni fornite dall'utente o raccolte dall'utilizzo dei loro servizi.",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Reject All",
                    showPreferencesBtn: "Gestisci preferenze"
                },
                preferencesModal: {
                    title: "Gestisci le preferenze sui cookie",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Reject All",
                    savePreferencesBtn: "Accept Selection",
                    closeIconLabel: "Close",
                    sections: [
                        {
                            title: "Questo sito web utilizza i cookie",
                            description: "Utilizziamo i cookie per personalizzare i contenuti e gli annunci, per fornire funzioni di social media e per analizzare il nostro traffico. Condividiamo inoltre le informazioni sull'utilizzo del nostro sito con i nostri partner di social media, pubblicità e analisi, che possono combinarle con altre informazioni fornite dall'utente o raccolte dall'utilizzo dei loro servizi"
                        },
                        {
                            title: "Necessari",
                            description: "I cookie necessari contribuiscono a rendere utilizzabile un sito web abilitando funzioni di base come la navigazione delle pagine e l'accesso alle aree sicure del sito. Il sito web non può funzionare correttamente senza questi cookie.",
                            linkedCategory: "necessari"
                        },
                        {
                            title: "Ulteriori informazioni",
                            description: "Per qualsiasi domanda in relazione alla politica sui cookie e alle vostre scelte, vi preghiamo di <a href=\"https://ucar-solutions.de/kontakt\">contattarci</a>"
                        }
                    ]
                }
            },
            nb: {
                consentModal: {
                    title: "Dette nettstedet bruker informasjonskapsler",
                    description: "Vi bruker informasjonskapsler for å tilpasse innhold og annonser, for å tilby funksjoner for sosiale medier og for å analysere trafikken vår. Vi deler også informasjon om din bruk av nettstedet vårt med våre partnere innen sosiale medier, annonsering og analyse, som kan kombinere den med annen informasjon du har gitt dem eller som de har samlet inn fra din bruk av deres tjenester.",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Reject All",
                    showPreferencesBtn: "Manage Preferences"
                },
                preferencesModal: {
                    title: "Manage Cookie Preferences",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Reject All",
                    savePreferencesBtn: "Godta valg",
                    closeIconLabel: "Close",
                    sections: [
                        {
                            title: "Dette nettstedet bruker informasjonskapsler",
                            description: "Vi bruker informasjonskapsler for å tilpasse innhold og annonser, for å tilby funksjoner for sosiale medier og for å analysere trafikken vår. Vi deler også informasjon om din bruk av nettstedet vårt med våre partnere innen sosiale medier, annonsering og analyse, som kan kombinere den med annen informasjon som du har gitt dem eller som de har samlet inn fra din bruk av deres tjenester."
                        },
                        {
                            title: "Necessary",
                            description: "Nødvendige informasjonskapsler bidrar til å gjøre et nettsted brukbart ved å muliggjøre grunnleggende funksjoner som sidenavigering og tilgang til sikre områder på nettstedet. Nettstedet kan ikke fungere skikkelig uten disse informasjonskapslene.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "More information",
                            description: "Hvis du har spørsmål om retningslinjene for informasjonskapsler og dine valg, kan du <a href=\"https://ucar-solutions.de/kontakt\">kontakte oss</a>"
                        }
                    ]
                }
            },
            pt: {
                consentModal: {
                    title: "Este sítio Web utiliza cookies",
                    description: "Utilizamos cookies para personalizar conteúdos e anúncios, para fornecer funcionalidades de redes sociais e para analisar o nosso tráfego. Também partilhamos informações sobre a sua utilização do nosso sítio com os nossos parceiros de redes sociais, publicidade e análise, que as podem combinar com outras informações que lhes tenha fornecido ou que tenham recolhido a partir da sua utilização dos respectivos serviços.",
                    acceptAllBtn: "Aceitar tudo",
                    acceptNecessaryBtn: "Rejeitar Tudo",
                    showPreferencesBtn: "Gerir preferências"
                },
                preferencesModal: {
                    title: "Gerir preferências de cookies",
                    acceptAllBtn: "Aceitar tudo",
                    acceptNecessaryBtn: "Rejeitar Tudo",
                    savePreferencesBtn: "Aceitar seleção",
                    closeIconLabel: "Fechar",
                    sections: [
                        {
                            title: "Este sítio Web utiliza cookies",
                            description: "Utilizamos cookies para personalizar conteúdos e anúncios, para fornecer funcionalidades de redes sociais e para analisar o nosso tráfego. Também partilhamos informações sobre a sua utilização do nosso sítio com os nossos parceiros de redes sociais, publicidade e análise, que podem combiná-las com outras informações que lhes tenha fornecido ou que tenham recolhido a partir da sua utilização dos respectivos serviços."
                        },
                        {
                            title: "Necessários",
                            description: "Os cookies necessários ajudam a tornar um sítio Web utilizável, permitindo funções básicas como a navegação na página e o acesso a áreas seguras do sítio Web. O sítio Web não pode funcionar corretamente sem estes cookies.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Mais informações",
                            description: "Para quaisquer questões relacionadas com a política de cookies e as suas escolhas, <a href=\"https://ucar-solutions.de/kontakt\">contacte-nos</a>"
                        }
                    ]
                }
            },
            sv: {
                consentModal: {
                    title: "Denna webbplats använder cookies",
                    description: "Vi använder cookies för att anpassa innehåll och annonser, för att tillhandahålla funktioner för sociala medier och för att analysera vår trafik. Vi delar också information om din användning av vår webbplats med våra partners inom sociala medier, reklam och analys som kan kombinera den med annan information som du har lämnat till dem eller som de har samlat in från din användning av deras tjänster.",
                    acceptAllBtn: "Acceptera alla",
                    acceptNecessaryBtn: "Avvisa alla",
                    showPreferencesBtn: "Hantera inställningar"
                },
                preferencesModal: {
                    title: "Hantera cookieinställningar",
                    acceptAllBtn: "Acceptera alla",
                    acceptNecessaryBtn: "Avvisa alla",
                    savePreferencesBtn: "Acceptera urval",
                    closeIconLabel: "Stäng",
                    sections: [
                        {
                            title: "Den här webbplatsen använder cookies",
                            description: "Vi använder cookies för att anpassa innehåll och annonser, för att tillhandahålla funktioner för sociala medier och för att analysera vår trafik. Vi delar också information om din användning av vår webbplats med våra partners inom sociala medier, reklam och analys som kan kombinera den med annan information som du har lämnat till dem eller som de har samlat in från din användning av deras tjänster."
                        },
                        {
                            title: "Nödvändig",
                            description: "Nödvändiga cookies hjälper till att göra en webbplats användbar genom att möjliggöra grundläggande funktioner som sidnavigering och åtkomst till säkra områden på webbplatsen. Webbplatsen kan inte fungera korrekt utan dessa cookies.",
                            linkedCategory: "nödvändiga"
                        },
                        {
                            title: "Mer information",
                            description: " <a href=\"https://ucar-solutions.de/kontakt\">Kontakta oss</a> om du har några frågor om policyn för cookies och dina val"
                        }
                    ]
                }
            },
            tr: {
                consentModal: {
                    title: "Bu web sitesi çerez kullanmaktadır",
                    description: "Çerezleri içeriği ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve trafiğimizi analiz etmek için kullanıyoruz. Ayrıca, sitemizi kullanımınızla ilgili bilgileri, onlara sağladığınız veya hizmetlerini kullanımınızdan topladıkları diğer bilgilerle birleştirebilecek sosyal medya, reklam ve analiz ortaklarımızla paylaşıyoruz.",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Tümünü Reddet",
                    showPreferencesBtn: "Manage Preferences"
                },
                preferencesModal: {
                    title: "Manage Cookie Preferences",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Tümünü Reddet",
                    savePreferencesBtn: "Seçimi Kabul Et",
                    closeIconLabel: "Close",
                    sections: [
                        {
                            title: "Bu web sitesi çerez kullanmaktadır",
                            description: "İçeriği ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve trafiğimizi analiz etmek için çerezler kullanıyoruz. Ayrıca sitemizi kullanımınızla ilgili bilgileri sosyal medya, reklam ve analiz ortaklarımızla paylaşırız; bu ortaklar bu bilgileri sizin onlara sağladığınız veya onların hizmetlerini kullanımınızdan topladıkları diğer bilgilerle birleştirebilir."
                        },
                        {
                            title: "Gerekli",
                            description: "Gerekli çerezler, sayfada gezinme ve web sitesinin güvenli alanlarına erişim gibi temel işlevleri etkinleştirerek bir web sitesini kullanılabilir hale getirmeye yardımcı olur. Web sitesi bu çerezler olmadan düzgün çalışamaz.",
                            linkedCategory: "gerekli"
                        },
                        {
                            title: "Daha fazla bilgi",
                            description: "Çerez politikası ve tercihlerinizle ilgili sorularınız için lütfen <a href=\"https://ucar-solutions.de/kontakt\">bizimle iletişime</a> geçin"
                        }
                    ]
                }
            },
            da: {
                consentModal: {
                    title: "Denne hjemmeside bruger cookies",
                    description: "Vi bruger cookies til at personliggøre indhold og annoncer, til at levere funktioner til sociale medier og til at analysere vores trafik. Vi deler også oplysninger om din brug af vores websted med vores partnere inden for sociale medier, annoncering og analyse, som kan kombinere dem med andre oplysninger, som du har givet dem, eller som de har indsamlet fra din brug af deres tjenester.",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Afvis alle",
                    showPreferencesBtn: "Manage Preferences"
                },
                preferencesModal: {
                    title: "Administrer cookie-præferencer",
                    acceptAllBtn: "Accepter alle",
                    acceptNecessaryBtn: "Afvis alle",
                    savePreferencesBtn: "Accepter valg",
                    closeIconLabel: "Luk",
                    sektioner: [
                        {
                            title: "Denne hjemmeside bruger cookies",
                            description: "Vi bruger cookies til at tilpasse indhold og annoncer, til at levere funktioner til sociale medier og til at analysere vores trafik. Vi deler også oplysninger om din brug af vores websted med vores partnere inden for sociale medier, annoncering og analyse, som kan kombinere dem med andre oplysninger, som du har givet dem, eller som de har indsamlet fra din brug af deres tjenester."
                        },
                        {
                            title: "Necessary",
                            description: "Nødvendige cookies hjælper med at gøre en hjemmeside brugbar ved at muliggøre grundlæggende funktioner som sidenavigation og adgang til sikre områder på hjemmesiden. Hjemmesiden kan ikke fungere korrekt uden disse cookies.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Mere information",
                            description: "Hvis du har spørgsmål til vores politik om cookies og dine valg, bedes du <a href=\"https://ucar-solutions.de/kontakt\">kontakte os</a>."
                        }
                    ]
                }
            },
            et: {
                consentModal: {
                    title: "See veebileht kasutab küpsiseid",
                    description: "Kasutame küpsiseid sisu ja reklaami isikupärastamiseks, sotsiaalmeedia funktsioonide pakkumiseks ja liikluse analüüsimiseks. Samuti jagame teavet teie poolt meie saidi kasutamise kohta meie sotsiaalmeedia-, reklaami- ja analüüsipartneritega, kes võivad seda kombineerida muu teabega, mille olete neile esitanud või mida nad on kogunud teie poolt nende teenuste kasutamisest.",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Reject All",
                    showPreferencesBtn: "Manage Preferences"
                },
                preferencesModal: {
                    title: "Manage Cookie Preferences",
                    acceptAllBtn: "Accept All",
                    acceptNecessaryBtn: "Reject All",
                    savePreferencesBtn: "Accept Selection",
                    closeIconLabel: "Sulge",
                    sections: [
                        {
                            title: "See veebileht kasutab küpsiseid",
                            description: "Kasutame küpsiseid sisu ja reklaamide isikupärastamiseks, sotsiaalmeedia funktsioonide pakkumiseks ja liikluse analüüsimiseks. Samuti jagame teavet teie poolt meie saidi kasutamise kohta meie sotsiaalmeedia-, reklaami- ja analüüsipartneritega, kes võivad seda kombineerida muu teabega, mida olete neile esitanud või mida nad on kogunud teie poolt nende teenuste kasutamisest."
                        },
                        {
                            title: "Vajalik",
                            description: "Vajalikud küpsised aitavad muuta veebisaidi kasutatavaks, võimaldades põhifunktsioone, nagu leheküljel navigeerimine ja juurdepääs veebisaidi turvalistele aladele. Ilma nende küpsisteta ei saa veebisait korralikult toimida.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Lisateave",
                            description: "Kui teil on küsimusi seoses küpsiste poliitika ja teie valikutega, <a href=\"https://ucar-solutions.de/kontakt\">võtke meiega ühendust</a>"
                        }
                    ]
                }
            },
            ru: {
                consentModal: {
                    title: "На этом сайте используются файлы cookie",
                    description: "Мы используем файлы cookie для персонализации контента и рекламы, для обеспечения функций социальных сетей и для анализа нашего трафика. Мы также передаем информацию о вашем использовании нашего сайта нашим партнерам по социальным сетям, рекламе и аналитике, которые могут объединять ее с другой информацией, которую вы им предоставили или которую они собрали в результате использования вами их услуг.",
                    acceptAllBtn: "Принять все",
                    AcceptNecessaryBtn: "Отклонить все",
                    showPreferencesBtn: "Manage Preferences"
                },
                preferencesModal: {
                    title: "Manage Cookie Preferences",
                    acceptAllBtn: "Принять все",
                    acceptNecessaryBtn: "Отклонить все",
                    savePreferencesBtn: "Принять выбор",
                    closeIconLabel: "Закрыть",
                    sections: [
                        {
                            title: "На этом сайте используются файлы cookie",
                            description: "Мы используем файлы cookie для персонализации контента и рекламы, для обеспечения функций социальных сетей и для анализа трафика. Мы также передаем информацию о вашем использовании нашего сайта нашим партнерам по социальным сетям, рекламе и аналитике, которые могут объединять ее с другой информацией, которую вы им предоставили или которую они собрали в результате использования вами их услуг."
                        },
                        {
                            title: "Necessary",
                            description: "Необходимые файлы cookie помогают сделать веб-сайт удобным для использования, обеспечивая такие основные функции, как навигация по странице и доступ к защищенным областям веб-сайта. Без этих файлов веб-сайт не может функционировать должным образом.",
                            linkedCategory: "необходимые"
                        },
                        {
                            title: "Дополнительная информация",
                            description: "По любым вопросам, связанным с политикой использования файлов cookie и вашим выбором, <a href=\"https://ucar-solutions.de/kontakt\">обращайтесь к нам</a>"
                        }
                    ]
                }
            },
            uk: {
                consentModal: {
                    title: "Цей веб-сайт використовує файли cookie",
                    description: "Ми використовуємо файли cookie для персоналізації контенту та реклами, надання функцій соціальних мереж та аналізу нашого трафіку. Ми також ділимося інформацією про використання вами нашого сайту з нашими партнерами з соціальних мереж, реклами та аналітики, які можуть об'єднувати її з іншою інформацією, яку ви надали їм або яку вони зібрали під час використання вами їхніх послуг.",
                    acceptAllBtn: "Прийняти всі",
                    acceptNecessaryBtn: "Відхилити все",
                    showPreferencesBtn: "Керувати налаштуваннями"
                },
                preferencesModal: {
                    title: "Керування налаштуваннями файлів cookie",
                    acceptAllBtn: "Прийняти всі",
                    acceptNecessaryBtn: "Відхилити все",
                    savePreferencesBtn: "Прийняти вибір",
                    closeIconLabel: "Закрити",
                    sections: [
                        {
                            title: "Цей веб-сайт використовує файли cookie",
                            description: "Ми використовуємо файли cookie для персоналізації контенту та оголошень, надання функцій соціальних мереж та аналізу нашого трафіку. Ми також ділимося інформацією про використання вами нашого сайту з нашими партнерами з соціальних мереж, реклами та аналітики, які можуть об'єднувати її з іншою інформацією, яку ви надали їм або яку вони зібрали під час використання вами їхніх послуг."
                        },
                        {
                            title: "Необхідні",
                            description: "Необхідні файли cookie допомагають зробити веб-сайт придатним для використання, вмикаючи основні функції, такі як навігація сторінками та доступ до захищених областей веб-сайту. Без цих файлів cookie веб-сайт не може функціонувати належним чином.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Додаткова інформація",
                            description: "Для будь-яких запитань стосовно політики щодо файлів cookie та вашого вибору, будь ласка, зв'яжіться з <a href=\"https://ucar-solutions.de/kontakt\">нами</a>"
                        }
                    ]
                }
            }
        }
    }
}

function onConsentCustom(c) {
    const cookie = c.cookie;
    // callback triggered on the first accept/reject action, and after each page load
    for (let i = 0; i < cookie.categories.length || 0; i++) {
        continue;
        switch (cookie.categories[i]) {
            case TOGGLE_NAME_NECESSARY:
                break;
            case TOGGLE_NAME_ANALYTICS:
                loadGoogleAnalytics();
                break;
            case TOGGLE_NAME_TARGETING:
                break;
        }

    }
}


function loadGoogleAnalytics() {
    const gId = 'G-1RCPCY7PMJ';

    if (true === googleAnalyticsLoaded(gId)) {
        return;
    }

    const gaScript = document.createElement('script');
    gaScript.async = true;
    gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gId;

    document.head.appendChild(gaScript);

    gaScript.onload = function () {
        console.log("script loaded, setting config")
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag(
            'config',
            gId,
            {
                'anonymize_ip': true
            }
        );
        console.log("setting config done")
    }
}

function googleAnalyticsLoaded(gid) {
    const scripts = document.head.getElementsByTagName('script');

    for (let i = 0; i < scripts.length; i++) {
        // Check if the src attribute of the script tag starts with the specified URL
        if (scripts[i].src.startsWith("https://www.googletagmanager.com/gtag/js?id=" + gid)) {
            console.log("Script tag found.");
            return true;
        }
    }

    console.log("Script tag not found.");
    return false;
}

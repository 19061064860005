<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary d-flex flex-row justify-content-between custom-bg">
    <a class="navbar-brand ms-3" href="#">
      <router-link class="text-white text-decoration-none" to="/">
        <img class="bi me-2" height="50" width="50" src="./../assets/logo-ucar-solutions.png" alt="">
      </router-link>
    </a>
    <div class="me-2">
      <router-link class="text-decoration-none text-black" to="/">{{ $t('application.title') }}</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      menuList: [],
      selectedLanguage: 'en',
      langs: ['de', 'en', 'tr'],
      languages: [
        {
          val: 'de',
          name: 'Deutsch (DE)'
        },
        {
          val: 'en',
          name: 'English (EN)'
        },
        {
          val: 'tr',
          name: 'Turkce (TR)'
        }
      ]
    }
  },
  methods: {
    onLanguageSwitch(event) {
      localStorage.setItem('locale', event.target.value);
    },
    navItemCollapse(index) {
      this.menuList = this.menuList.map((item, i) => {
        item.expand = !item.expand
        if (i !== index) {
          item.expand = false
        }
        return item
      })
    }
  }
}
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}

@media (max-width: 1279px) {
  .desktop-menu {
    display: none !important;
  }
}

@media (min-width: 1279px) {
  .mobile-menu {
    display: none !important;
  }
}

.custom-bg{
  background: #F3F3F4 !important;
}
#user-login {
  background-color: #1B2B65;
  border-radius: 5px;
  padding: 10px;
}
</style>

<template>
<!--  <div class="row d-flex flex-grow-1 mt-5 mt-sm-0">-->
<!--    <div class="container d-flex flex-column justify-content-center mt-5 w-lg-35 w-100">-->
<!--      <section>-->
<!--        <div class="container text-center">-->
<!--          <h1 class="display-4 bold">{{ $t("notFound.title") }}</h1>-->
<!--          <h4 class="text-primary">{{ $t("notFound.description") }}</h4>-->
<!--        </div>-->
<!--      </section>-->
<!--      <section class="w-lg-100 d-flex justify-content-center flex-column">-->
<!--        <img class="d-flex w-50 w-lg-100 align-self-center" src="./../assets/svg/logo.svg"-->
<!--             :alt="$t('frontPage.logoAlt')">-->
<!--      </section>-->

<!--      <section class="mt-5">-->
<!--        <BackToApp></BackToApp>-->
<!--      </section>-->

<!--    </div>-->
<!--    <Footer></Footer>-->
<!--  </div>-->
</template>

<script>
import Navigation from "@/components/Navigation";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Footer from "@/components/Footer.vue";
import BackToApp from "@/components/BackToApp.vue";
import RegisterForm from "@/components/RegisterForm.vue";

export default {
  name: "NotFound",
  components: {RegisterForm, BackToApp, Footer, FontAwesomeIcon, Navigation},
  setup() {
    function toApp() {
      window.location = '/'
    }

    toApp();

    return {
      toApp: toApp
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="row d-flex flex-grow-1">
    <Navigation></Navigation>
    <div class="container mw-lg-60percent d-flex flex-column justify-content-center mt-5 mb-5">
      <div class="row align-self-center" v-if="state.error !== null">
        <div class="col">
          <div class="alert alert-danger" role="alert">
            {{ state.error }}
          </div>
        </div>
      </div>
      <div class="row">
        <form>
          <div class="form-group">
            <div class="card">
              <div class="card-header bg-white text-primary">
                <h2 class="p-1 m-0">
                  {{ $t('application.title') }}
                </h2>
              </div>
              <div class="card-body">
                <div class="container border my-border mt-3">
                  <div class="row mb-3">
                    <div class="col">
                      <div class="row">
                        <div class="align-items-start d-flex col-8">
                          <small>
                            {{ state.result.text }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import moment from "moment";
import {onMounted, reactive} from "vue";

export default {
  name: "Application",
  components: {Navigation},
  setup() {
    const state = reactive({
      result: {
        text: null
      },
      saving: false,
      error: null
    });

    onMounted(
        () => {
          const userLocale =
              navigator.languages && navigator.languages.length
                  ? navigator.languages[0]
                  : navigator.language;
          moment(userLocale);
        }
    );
    return {
      state: state
    }
  }

}
</script>

<style scoped>

</style>
